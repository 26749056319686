import { render, staticRenderFns } from "./VueDfTemplateView.vue?vue&type=template&id=83f9065c&scoped=true&"
import script from "./VueDfTemplateView.vue?vue&type=script&lang=js&"
export * from "./VueDfTemplateView.vue?vue&type=script&lang=js&"
import style0 from "./VueDfTemplateView.vue?vue&type=style&index=0&id=83f9065c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83f9065c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83f9065c')) {
      api.createRecord('83f9065c', component.options)
    } else {
      api.reload('83f9065c', component.options)
    }
    module.hot.accept("./VueDfTemplateView.vue?vue&type=template&id=83f9065c&scoped=true&", function () {
      api.rerender('83f9065c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-components/src/components/vueDfComponents/VueDfTemplate/VueDfTemplateView.vue"
export default component.exports