<template>
  <div>
    <vue-feed-preview-component :config="model.config.data" :data="data.result"></vue-feed-preview-component>
  </div>
</template>

<script>
  import VueFeedPreviewComponent from './VueFeedPreviewComponent'
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

  export default {
    name: "VueDfFeedView",
    mixins: [widgetComponentViewMixins],
    components: {
      VueFeedPreviewComponent
    },
    data() {
      return {
        data: {
          result: null
        },
        state: {
          isLoading: false
        }
      }
    },
    beforeMount() {
      this.loadRss();
    },
    /*watch: {
      'shouldReload'(value) {
        this.loadRss();
      }
    },*/
    methods: {
      loadRss: function () {
        const that = this;
        this.data.result = null;
        this.state.isLoading = true;
        console.log('Loading feed', this.model);
        const url = this.$jsulator.evaluate('$config.data.url$', this.model);
        if (url) {
          const body = {
            url: url,
            size: this.model.config.data.size || 10
          }
          this.$http.post(this.$appConfig.appConfig.apiBase +'/v1/feed/dashboard/monitor', body)
            .then((response) => {
              that.data.result = response.data.result;
              //console.log(that.data.result);
              that.state.isLoading = false;
            }).catch((error) => {
            console.error(error);
            that.state.isLoading = false;
          })

        }

      }
    }
  }
</script>

<style scoped>

</style>
