var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid group-operator" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "dropdown", class: { show: _vm.expanded } }, [
          _c("button", {
            staticClass: "btn btn-primary dropdown-toggle",
            attrs: {
              type: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": String(_vm.expanded),
            },
            domProps: { textContent: _vm._s(_vm.groupCtrl.currentOperator) },
            on: {
              click: function ($event) {
                _vm.expanded = !_vm.expanded
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "dropdown-menu",
              class: { show: _vm.expanded },
              attrs: { "aria-labelledby": "dropdownMenuButton" },
            },
            _vm._l(_vm.groupCtrl.operators, function (operator) {
              return _c("a", {
                key: operator.identifier,
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                domProps: { textContent: _vm._s(operator.name) },
                on: {
                  click: function ($event) {
                    return _vm.selectOperator(operator.identifier)
                  },
                },
              })
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }