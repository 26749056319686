let httpService = {
  name: 'http',
  storage: null,
  http: null,
  fullPath: null,
  resultPropertyName: 'result',
  appKey: null,
  idPropertyName: 'id',
  isDebug: false,
  resolveDebug() {
    return this.isDebug ? '&debug=true' : '';
  },
  loadDashboardPreviewItem: function (id, fields, next) {
    const url = this.fullPath + '/preview/user/' + id + '?appKey=' + this.appKey + (fields ? ('&fields=' + fields) : '') + this.resolveDebug();
    this._executeGetRequest(url, next);
  },
  loadAllDashboardPreviewItems: function (dashId, fields, next) {
    const url = this.fullPath + '/preview/all/' + dashId + '?appKey=' + this.appKey + (fields ? ('&fields=' + fields) : '') + this.resolveDebug();
    this._executeGetRequest(url, next);
  },
  saveDashboardPreviewItem: function (previewObj, next) {
    const url = this.fullPath + '/preview?appKey=' + this.appKey + this.resolveDebug();
    this._post(url, previewObj, next);
  },
  removeDashboardPreviewItem: function (id, next) {
    const url = this.fullPath + '/preview/user/' + id + '?appKey=' + this.appKey + this.resolveDebug();
    this._delete(url, next);
  },
  removeDashboardAllPreviewItems: function (dashId) {
    const url = this.fullPath + '/preview/all/' + dashId + '?appKey=' + this.appKey + this.resolveDebug();
    this._delete(url, next);
  },
  loadPersonalDashboard: function (fields, id, next) {
    const url = this.fullPath + '/personal/' + id + '?appKey=' + this.appKey + (fields ? ('&fields=' + fields) : '');
    this._executeGetRequest(url, next);
  },
  savePersonalDashboard: function (dashboardModel, next) {
    const url = this.fullPath + '/personal?appKey=' + this.appKey;
    //   console.log('Saving personale dashboards', url);
    this._post(url, dashboardModel, next);
  },
  loadDashboards: function (fields, next) {
    const url = this.fullPath + '?appKey=' + this.appKey + (fields ? ('&fields=' + fields) : '');
    this._executeGetRequest(url, next);
  },
  loadModels: function (key, next) {
    const url = this.fullPath + '/' + key + '?appKey=' + this.appKey;
    this._executeGetRequest(url, next);
  },
  _executeGetRequest: function (url, next) {
    const that = this;
    try {
      this.http.get(url).then((response) => {
        const result = response.data[that.resultPropertyName];
        if (result) {
          if (next)
            next(result); // next(result.value);
          else
            return result; //return result.value;

        } else {
          if (next)
            next([]);
          else
            return [];
        }
      }).catch((error) => {
        console.error(error);
        // next(models);
      })
    } catch (e) {
      console.error(e);
      //  next(models);
    }
  },
  saveModels: function (key, models, next) {
    //  this.storage.set(key, models);
    const url = this.fullPath + '?appKey=' + this.appKey;

    const saveObject = {
      value: models
    };
    saveObject[this.idPropertyName] = key;

    try {
      this.http.post(url, saveObject).then((response) => {
        next({data: models});
      }).catch((error) => {
        console.error(error);
        next({data: models});
      })
    } catch (e) {
      console.error(e);
      next({data: models});
    }
  },
  _post: function (url, saveObject, next) {
    const that = this;
    try {
      this.http.post(url, saveObject).then((response) => {
        next({data: response.data[that.resultPropertyName]});
      }).catch((error) => {
        console.error(error);
        next(null);
      })
    } catch (e) {
      console.error(e);
      next(null);
    }
  },
  _delete: function (url, next) {
    this.http.delete(url).then(function (response) {
      next(response.result);
    }).catch((error) => {
      console.error(error);
      next(null);
    })
  },
  deleteWidget: function (widget, next) {
    const url = this.fullPath + '/widgets/' + widget._id + '?appKey=' + this.appKey;
    this._delete(url, next);
  },
  saveWidget: function (newWidget, next) {
    const url = this.fullPath + '/widgets?appKey=' + this.appKey;
    // console.log('Saving widget', url);
    newWidget[this.idPropertyName] = newWidget._id;
    this._post(url, newWidget, next);
  },
  loadWidgets: function (filter, next) {
    const url = this.fullPath + '/widgets?appKey=' + this.appKey;
    //console.log('Loading tenant widgets widgets', url);
    this._executeGetRequest(url, next);
  },
  loadWidgetByType: function (type, next) {
    const url = this.fullPath + '/widgets/type/' + type + '?appKey=' + this.appKey;
    // console.log('Loading tenant widgets widgets', url);
    this._executeGetRequest(url, next);
  },
  loadWidgetById: function (id, next) {
    const url = this.fullPath + '/widgets/' + id + '?appKey=' + this.appKey;
    // console.log('Loading tenant widgets widgets', url);
    this._executeGetRequest(url, next);
  },
  loadWidgetModalInstances: function (filter, next) {
    const url = this.fullPath + '/widgetmodalinstance?appKey=' + this.appKey;
    this._executeGetRequest(url, next);
  },
  loadWidgetModalInstanceById: function (id, next) {
    const url = this.fullPath + '/widgetmodalinstance/' + id + '?appKey=' + this.appKey;
    this._executeGetRequest(url, next);
  },
  saveWidgetModalInstance: function (widgetModalInstance, next) {
    const url = this.fullPath + '/widgetmodalinstance?appKey=' + this.appKey;
    widgetModalInstance[this.idPropertyName] = widgetModalInstance.id;
    this._post(url, widgetModalInstance, next);
  },
  deleteWidgetModalInstance: function (id, next) {
    const url = this.fullPath + '/widgetmodalinstance/' + id + '?appKey=' + this.appKey;
    this._delete(url, next);

  },
  install(Vue, options) {
    this.storage = Vue.ls;
    this.fullPath = options.fullPath;
    if (options.resultPropertyName)
      this.resultPropertyName = options.resultPropertyName;
    if (options.idPropertyName)
      this.idPropertyName = options.idPropertyName;

    this.appKey = options.appKey;

    this.http = Vue.axios;
  }
}

export default httpService;
