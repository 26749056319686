<template>
  <div v-if="data && data.length>0" class="feed-view-wrapper">
    <!--<pre>{{data}}</pre>-->
    <template v-if="config.viewType==='MAGAZINE'">
      <ul class="list-unstyled feed-view magazine">
        <li v-for="item in data" :key="item.link">
          <div class="feed-item-container">
            <div v-if="resolveImage(item)" class="feed-item-image"
                 :style="'background-image: url('+resolveImage(item)+')'">
            </div>
            <div class="feed-item-content small">
              <a class="feed-item-title" target="_blank" :href="item.link">{{item.title}}</a>
              <div class="feed-item-meta text-muted">
                <span>
                  <div>
                    <span class="author">{{item.author}}</span> //
                    <span class="categories" v-if="item.categories && item.categories.length>0">{{ resolveCategories(item)}}</span>
                    <span class="date">{{ resolveDate(item) }}</span> //
                    <span class="source">{{resolveSource(item)}}</span>
                  </div>
                </span>
              </div>
              <div class="feed-item-summary">
                <p v-html="resolveItemSummary(item)"></p>
              </div>
              <a class="feed-item-visit" target="_blank" :href="item.link">Read on site</a>
            </div>

          </div>
        </li>
      </ul>
    </template>
    <template v-if="config.viewType==='MEDIUM'">
      <ul class="list-unstyled feed-view medium">
        <li v-for="item in data" :key="item.link">
          <div class="feed-item-container">
            <div class="feed-item-content small">
              <a class="feed-item-title" target="_blank" :href="item.link">{{item.title}}</a>
              <div class="feed-item-meta text-muted">
                <span>
                  <div>
                    <span class="author">{{item.author}}</span> //
                    <span class="categories" v-if="item.categories && item.categories.length>0">{{ resolveCategories(item)}}</span>
                    <span class="date">{{ resolveDate(item) }}</span> //
                    <span class="source">{{resolveSource(item)}}</span>
                  </div>
                </span>
              </div>
              <div class="feed-item-summary">
                <p v-html="resolveItemSummary(item)"></p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <template v-if="config.viewType==='COMPACT'">
      <ul class="list-unstyled feed-view compact">
        <li v-for="item in data" :key="item.link">
          <div class="feed-item-container">
            <div class="feed-item-content small">
              <a class="feed-item-title" target="_blank" :href="item.link">{{item.title}}</a>
              <div class="feed-item-meta text-muted">
                <span>
                  <div>
                    <span class="author">{{item.author}}</span> //
                    <span class="categories" v-if="item.categories && item.categories.length>0">{{ resolveCategories(item)}}</span>
                    <span class="date">{{ resolveDate(item) }}</span> //
                    <span class="source">{{resolveSource(item)}}</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <template v-if="config.viewType==='FULL'">
      <ul class="list-unstyled feed-view">
        <li v-for="item in data" :key="item.link">
          <div class="feed-item-container full">
            <div v-if="resolveImage(item)" class="feed-item-image"
                 :style="'background-image: url('+resolveImage(item)+')'">
            </div>
            <div class="feed-item-content small">
              <a class="feed-item-title" target="_blank" :href="item.link">{{item.title}}</a>
              <div class="feed-item-meta text-muted">
                <span>
                  <div>
                    <span class="author">{{item.author}}</span> //
                    <span class="categories" v-if="item.categories && item.categories.length>0">{{ resolveCategories(item)}}</span>
                    <span class="date">{{ resolveDate(item) }}</span> //
                    <span class="source">{{resolveSource(item)}}</span>
                  </div>
                </span>
              </div>
              <div class="feed-item-summary">
                <p v-html="resolveItemSummary(item)"></p>
              </div>
              <a class="feed-item-visit" target="_blank" :href="item.link">Read on site</a>
            </div>

          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
  import DateTime from 'luxon/src/datetime.js'
  export default {
    name: "VueFeedPreviewComponent",
    props: ['data', 'config'],
    data(){
      return {
      }
    },
    methods: {
      resolveCategories: function (item) {
        if (item.categories)
          return item.categories.join(',') + ' // ';
        return null;
      },
      timeAgo: (date) => {
        let dateTime = DateTime.fromISO(date)
        let uunits = [
          'year',
          'month',
          'week',
          'day',
          'hour',
          'minute',
          'second',
        ];
        const diff = dateTime.diffNow().shiftTo(...uunits);
        const unit = uunits.find((unit) => diff.get(unit) !== 0) || 'second';

        const relativeFormatter = new Intl.RelativeTimeFormat('en', {
          numeric: 'auto',
        });
        const tt = Math.trunc(diff.as(unit));
        if (tt) {
          const formated = relativeFormatter.format(tt, unit);
          return formated;
        }else{
          return '';
        }
      },
      resolveDate: function (item) {
        return this.timeAgo(item.date);
      },
      resolveSource: function (item) {
        let creator = undefined;
        if (item['dc:creator'] && item['dc:creator']['#']){
          creator = item['dc:creator']['#'];
        }
                    return item.meta.copyright ||  creator || item.meta.link;
      },
      resolveItemSummary: function (item) {
        let itemSummary =  item.summary;
        console.log('Item Summary', itemSummary);
        itemSummary = this._cleanSummary(itemSummary);
        return itemSummary;
      },
      _cleanSummary: function(htmlText){
        if(htmlText) {
          htmlText =   htmlText.replace(/(width=")\d+("\W+height=")\d+/,' ');
        }
        return htmlText;
      },
      resolveImage: function (item) {
        let src = item.enclosures && item.enclosures.length > 0 ? item.enclosures[0].url : null;
        if (!src) {
          src = '';
        }
        return src;
      }
    }
  }
</script>

<style scoped>

  .feed-item-container {
    border-bottom: 1px solid #cdcdcd;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 15px;
    margin-top: 0;
    cursor: pointer;
    text-rendering: optimizeLegibility;
  }

  .feed-item-container.full {
    display: -webkit-box !important;
    display: block !important;
  }

  .feed-item-meta {
    /*color: #bdbdbd;*/
    line-height: 17px;
  }

  .feed-item-meta div {
    display: inline;
    color: #bcbcbc;
  }


  .feed-item-image {
    border-radius: 0.3rem;
    height: 150px;
    margin-right: 20px;
    width: 150px;
  }

  .feed-item-container.full > .feed-item-image {
    text-align: center;
    margin-bottom: 10px;
   /* height: 100%;*/
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .feed-item-summary {
    margin-top: 10px;
  }

  .feed-item-content {
    -webkit-flex: 1;
    flex: 1;
    color: #797979;
  }

  .feed-item-title {
    color: #555555;
    font-weight: bold;
    font-size: initial;
  }

  .feed-item-visit {
    width: 100%;
    display: block;
    color: #757575;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;


    margin-bottom: 1.5rem;
    padding-top: 7px;
    padding-bottom: 7px;


    font-weight: 700;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;

    text-decoration: none;
    cursor: pointer;
    outline: 0;
    text-align: center;
    vertical-align: top;
    box-sizing: border-box;
    letter-spacing: 0;
    box-shadow: none;
    transition: background-color 0.2s linear, color 0.2s linear;
  }

</style>
