<template>
  <div class="wrapper">
    <div class="main-panel-full">
      <div class="row">
        <div class="col-12">
          <dashboard-content></dashboard-content>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import DashboardContent from './Content.vue'

export default {
  name: "DashboardLoginLayout",
  components: {
    DashboardContent
  }
}
</script>

<style>

.auth-wrapper {

  font-family: sans-serif;
  background: linear-gradient(#141e30, #243b55);
}

.login-container.velmld-parent {
  position: initial!important;
}

.auth-box {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 300px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.auth-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.auth-box .user-box {
  position: relative;
  display: flex;
}

.auth-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-container a {
  padding: 15px 15px 0px 15px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /*border: 0px !important;*/
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.auth-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.auth-box .user-box input ~ label,
.auth-box .user-box input:focus ~ label,
.auth-box .user-box input:valid ~ label {
  top: -15px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.auth-box form a {
  position: relative;
  display: inline-block;
  /*padding: 10px 20px;*/
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  /*margin-top: 40px;*/
  letter-spacing: 4px
}

.auth-box a span {
  position: absolute;
  display: block;
}
.error-container span{
  /*color: #ffe6e0;*/
}
</style>
