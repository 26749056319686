/*const tableMarkup = "<b-table :bordered=\"tableDef.bordered\" :borderless=\"tableDef.borderless\" :dark=\"tableDef.dark\"" +
  "             :fields=\"resolveFields()\" :foot-variant=\"tableDef.footVariant\"\ :footClone=\"tableDef.footClone\"" +
  "             :head-variant=\"tableDef.headVariant\" :hover=\"tableDef.hover\" :items=\"data\"\n" +
  "             :no-border-collapse=\"tableDef.noBorderCollapse\" :outlined=\"tableDef.outlined\" :select-mode=\"selectMode\"\n" +
  "             :stacked=\"tableDef.stacked\" :caption-top=\"tableDef.captionTop\" :selectable=\"selectable\" " +
  "             :small=\"tableDef.small\" :striped=\"tableDef.striped\" :table-variant=\"tableDef.tableVariant\"\n" +
  "             @row-selected=\"onRowSelected\">\n" +
  "      <template v-if=\"tableDef.showCaption\" v-slot:table-caption>{{tableDef.caption}}</template>\n" +
  "      <template v-slot:table-busy>\n" +
  "        <div class=\"text-center text-danger my-2\">\n" +
  "          <b-spinner class=\"align-middle\"></b-spinner>\n" +
  "          <strong>Loading...</strong>\n" +
  "        </div>\n" +
  "      </template>\n" +
  "    </b-table>"*/

const tableUtils = {
  getTableId: function (tableDef, widgetId) {
    return 'table_' + widgetId;
  },
  buildBasicTableMarkup: function (tableDef, pagination, widgetId) {
    let tableDefaultMarkup = "<b-table id=\"" + this.getTableId(tableDef, widgetId) + "\" ref=\"" + this.getTableId(tableDef, widgetId) + "\"" +
      "             :bordered=\"tableDef.bordered\" :borderless=\"tableDef.borderless\" :dark=\"tableDef.dark\"" +
      "             :fields=\"resolveFields()\" :foot-variant=\"tableDef.footVariant\"\ :footClone=\"tableDef.footClone\"" +
      "             :sticky-header=\"tableDef.stickyHeader\" :responsive=\"tableDef.responsive\"" +
      "             :head-variant=\"tableDef.headVariant\" :selected-variant=\"tableDef.selectedRowVariant\" :hover=\"tableDef.hover\" :items=\"data\"\n" +
      "             :no-border-collapse=\"tableDef.noBorderCollapse\" :outlined=\"tableDef.outlined\" :select-mode=\"selectMode\"\n" +
      "             :stacked=\"tableDef.stacked\" :caption-top=\"tableDef.captionTop\" :selectable=\"selectable\" " +
      "             :tbody-tr-class=\"determineActive\"    "+
      "             :small=\"tableDef.small\" :striped=\"tableDef.striped\" :table-variant=\"tableDef.tableVariant\"\n";

    if (pagination && pagination.usePagination) {
      tableDefaultMarkup += ' :per-page=\"pagination.perPage\" :current-page=\"pagination.currentPage\"';
    }

    tableDefaultMarkup += "             @row-selected=\"onRowSelected\">\n";
    return tableDefaultMarkup;
  },
  buildTableBusy: function (tableDef) {
    if (tableDef.tableBusy) {
      let t = "<template v-slot:table-busy>";
      let c = "<div class=\"text-center text-danger my-2\">\n" +
        "          <b-spinner class=\"align-middle\"></b-spinner>\n" +
        "          <strong>Loading...</strong>\n" +
        "        </div>\n";
      if (tableDef.tableBusyHtml) {
        c = tableDef.tableBusyHtml;
      }
      t += (c + "</template>");
      return t;
    }
  },
  buildCaption: function (tableDef) {
    if (tableDef.captionHtml) {
      const c = "<template v-if=\"tableDef.showCaption\" v-slot:table-caption><span v-html=\"tableDef.captionHtml\"></span></template>";
      return c;
    }
  },
  buildRows: function (tableDef) {
    if (tableDef.rowDetails) {
      const c = tableDef.rowDetails;
      return c;
    }
  },
  buildFields: function (fields) {
    if (fields) {
      let c = '';
      fields.forEach(function (field) {
        if (field.cellFormat) {
          let tmp = '<template v-slot:cell(' + field.key.toLowerCase() + ')="data">';
          tmp += field.cellFormat;
          tmp += '</template>\n';
          c += tmp;
        }
      });
      return c;
    }
  },
  buildFooter: function(tableDef){
    if (tableDef.footer){
      let tmp = '<template #foot()="data">';
      tmp+=  '<i>Footer</i>'
      tmp+= '</template>'
      return tmp;
    }
  },
  buildPagination: function (tableDef, pagination, widgetId) {
    //if (tableDef && tableDef.includePagination){
    let c = '<b-pagination\n' +
      '      v-model="pagination.currentPage"\n' +
      '      :total-rows="pagination.totalRows"\n' +
      '      :per-page="pagination.perPage"\n' +
      '      aria-controls="' + this.getTableId(tableDef, widgetId) + '"\n' +
      '    ></b-pagination>\n';
    return c;
    //}
  },
  buildMarkup(configData, widgetId) {
    // console.log('Table configData', configData);
    const tableDef = configData.tableDefinition;
    const fieldsDef = configData.fields;

    let c = '';
    let tableDefaultMarkup = '<div class="overflow-auto">';

    if (configData.pagination && configData.pagination.usePagination && configData.pagination.position && (configData.pagination.position === 'Top' || configData.pagination.position === 'Both')) {
      c = this.buildPagination(tableDef, configData.pagination, widgetId);
      if (c) {
        tableDefaultMarkup += c;
      }
    }

    const table = this.buildBasicTableMarkup(tableDef, configData.pagination, widgetId);
    if (table) {
      tableDefaultMarkup += table;
    }

    if (tableDef) {
      c = this.buildCaption(tableDef);
      if (c)
        tableDefaultMarkup += c;

      c = this.buildTableBusy(tableDef)
      if (c)
        tableDefaultMarkup += c;

      c = this.buildFields(fieldsDef);
      if (c)
        tableDefaultMarkup += c;

      c = this.buildRows(tableDef);
      if (c)
        tableDefaultMarkup += c;
    }

    tableDefaultMarkup += "</b-table>";

    if (configData.pagination && configData.pagination.usePagination && configData.pagination.position && (configData.pagination.position === 'Bottom' || configData.pagination.position === 'Both')) {
      c = this.buildPagination(tableDef, configData.pagination, widgetId);
      if (c) {
        tableDefaultMarkup += c;
      }
    }

    tableDefaultMarkup += '</div>';

   // console.log('Created table markup');

    return tableDefaultMarkup;
    //return tableMarkup
  }
}

export default tableUtils
