import VueDfBpmnDiagramView from './VueDfBpmnDiagramView'
import VueDfBpmnDiagramEdit from './VueDfBpmnDiagramEdit'

let widget = {
  type: 'VueDfBpmnDiagram',
  title: 'BPMN Diagram',
  description: 'BPMN Diagram',
  category: 'BPMN',
  editComponent: 'VueDfBpmnDiagramEdit',
  viewComponent: 'VueDfBpmnDiagramView',
  dashboards:[],
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfBpmnDiagramView.name, VueDfBpmnDiagramView)
    Vue.component(VueDfBpmnDiagramEdit.name, VueDfBpmnDiagramEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
