var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "containers" }, [
    _vm._v("\n  TEST 1\n  "),
    _c("div", { ref: "canvas", staticClass: "canvas" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }