<template>
  <div>
    <b-tabs>
      <b-tab title="Data Type Definition">
        <schema-form :form="resolvedForm" :schema="resolvedSchema" @validationResult="onValidationResult"
                     v-model="model.config.data"></schema-form>
      </b-tab>
      <b-tab title="Data Preview">
        <div class="data-preview p-2">
          <strong>{{model.config.data.dataType}}</strong>
          <hr/>
          <component :is="componentType" :value="dataToPreview"></component>
        </div>
      </b-tab>

    </b-tabs>
  </div>

</template>

<script>
  import widgetComponentEditMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins";
  import previewMixin from "./previewMixin";
  import {BTabs, BTab} from 'bootstrap-vue'

  export default {
    name: "VueDfDataPreviewEdit",
    mixins: [widgetComponentEditMixins, previewMixin],
    components: {BTab, BTabs},
    data() {
      return {
        schemaForm: {
          schema: {},
          form: []
        },
        schemaFormCustom: {
          schemaProperties: {
            "dataType": {
              "type": "string",
              "title": "Data Type",
              "description": "Select data type",
              "default": "UNKNOWN"
            }
          },
          formItem: {
            title: 'Common',
            items: [
              {
                "items": [
                  {
                    "titleMap": [
                      {
                        "value": "UNKNOWN",
                        "name": "Uknown"
                      },
                      {
                        "value": "RowTypeView",
                        "name": "Row"
                      },
                      {
                        "name": "Column (CSV)",
                        "value": "ColumnTypeView"
                      },
                      {
                        "name": "Hierarchical (Json)",
                        "value": "HierarchicalTypeView"
                      }
                    ],
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "type": "radiosinline",
                    "key": "dataType",
                    "guid": "541eef05-fa1f-4b50-b299-6d359ec65924",
                    "name": "radiosinline"
                  }
                ],
                "type": "section",
                "guid": "a71440d3-0be7-49cc-be08-43f896ddb91f",
                "name": "section"
              }
            ]
          }
        },
        dataToPreview: [],
        hierarchicalData: [
          {
            name: 'item1',
            children: [
              {
                name: 'child1'
              },
              {
                name: 'child2'
              }
            ],
            child: {
              name: "document1"
            }
          },
          {
            name: 'item2',
            children: [
              {
                name: 'child2'
              },
              {
                name: 'child3'
              }
            ],
            child: {
              name: "document2"
            }
          }
        ],
        columnData: [
          ['name', 'value', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          ['item1', 12],
          ['item2', 22],
          ['item3', 32],
          ['item4', 12],

        ],
        rowData: [
          {
            name: 'Item1',
            value: 56
          },
          {
            name: 'Item2',
            value: 67
          },
          {
            name: 'Item3',
            value: 78
          },
          {
            name: 'Item4',
            value: 56
          },
          {
            name: 'Item5',
            value: 67
          },
          {
            name: 'Item6',
            value: 78
          },
          {
            name: 'Item7',
            value: 56
          },
          {
            name: 'Item8',
            value: 67
          },
          {
            name: 'Item9',
            value: 78
          }
        ],
        componentType: ''
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    watch: {
      'model.config.data.dataType': {
        handler(newVal, oldVal) {
          this.previewDataTypeChanged(newVal);
        },
        immediate: true
      }
    },
    methods: {
      previewDataTypeChanged: function (newVal) {
        if (!newVal || newVal === 'UNKNOWN') {
          this.componentType = 'HierarchicalTypeView'
        } else {
          this.componentType = newVal;
        }
        this.prepareDataToPreview();
      },
      prepareDataToPreview: function () {
        switch (this.componentType) {
          case "RowTypeView":
            this.dataToPreview = this.rowData;
            break;
          case "ColumnTypeView":
            this.dataToPreview = this.columnData;
            break;
          case "HierarchicalTypeView":
            this.dataToPreview = this.hierarchicalData;
            break;
        }
      },
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.schema.properties.dataType = this.schemaFormCustom.schemaProperties.dataType;
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);
        this.schemaForm = schemaFormSourceDestination;

      },
      onValidationResult: function (value) {
      }
    }
  }
</script>

<style scoped>

</style>
