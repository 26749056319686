var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c("div", { staticClass: "bpmn-container" }, [
        _vm.visible
          ? _c("div", { staticClass: "bpmn-container-holder" }, [
              _vm.isEditor === "EDITOR"
                ? _c(
                    "div",
                    { staticClass: "bpmn-editor-container" },
                    [
                      _vm.bpmnValue
                        ? _c("bpmn-editor", {
                            style: { height: this.model.config.data.height },
                            attrs: { value: _vm.bpmnValue },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEditor === "VIEWER"
                ? _c(
                    "div",
                    { staticClass: "bpmn-viewer-container" },
                    [
                      _vm.bpmnValue
                        ? _c("bpmn-viewer", {
                            style: { height: this.model.config.data.height },
                            attrs: { "xml-data": _vm.bpmnValue },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }