var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c("div", { staticClass: "widget-schema-form-wrapper" }, [
        _c(
          "div",
          { staticClass: "widget-schema-form" },
          [
            _c("schema-form", {
              ref: "schemaForm",
              attrs: { form: _vm.resolveForm(), schema: _vm.resolveSchema() },
              on: { validationResult: _vm.onValidationResult },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }