var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    [
      _c(
        "b-tab",
        { attrs: { title: "Data Definition" } },
        [
          _c("schema-form", {
            attrs: { form: _vm.resolvedForm, schema: _vm.resolvedSchema },
            model: {
              value: _vm.model.config.data,
              callback: function ($$v) {
                _vm.$set(_vm.model.config, "data", $$v)
              },
              expression: "model.config.data",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tab",
        {
          attrs: { title: "Chart Definition" },
          on: { click: _vm.onChartDefinitionClick },
        },
        [
          _vm.renderChart
            ? _c("chart-definition", {
                attrs: { config: _vm.chartConfig, data: _vm.chartData },
                on: { chartconfigupdated: _vm.chartConfigUpdated },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }