import VueDfWidgetTemplateView from './VueDfWidgetTemplateView'
import VueDfWidgetTemplateEdit from './VueDfWidgetTemplateEdit'

let widget = {
  type: 'VueDfWidgetTemplate',
  title: 'Widget Template',
  description: 'Render Widget Template',
  category: 'Template',
  editComponent: 'VueDfWidgetTemplateEdit',
  viewComponent: 'VueDfWidgetTemplateView',
  editableSettings:{
    editable: true
  },
  frameless: true,
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfWidgetTemplateView.name, VueDfWidgetTemplateView)
    Vue.component(VueDfWidgetTemplateEdit.name, VueDfWidgetTemplateEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
