var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { staticClass: "charting-container" },
        [
          _vm.renderChart
            ? _c("vue-df-e-chart-wrapper", {
                ref: "chartWrapper",
                attrs: {
                  allowedResizeDirections: _vm.allowedResizeDirections,
                  "chart-config": _vm.chartConfig,
                  dataset: _vm.chartDataset,
                  dimensions: _vm.chartDimensions,
                  height: _vm.contentHeight,
                  width: "100%",
                  resizable: _vm.contentResizable,
                },
                on: { onresize: _vm.onResize },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }