import VueDfDataPreviewView from './VueDfDataPreviewView'
import VueDfDataPreviewEdit from './VueDfDataPreviewEdit'

let widget = {
  type: 'VueDfDataPreview',
  title: 'Data Preview',
  description: 'Configure Data Preview widget',
  editComponent: 'VueDfDataPreviewEdit',
  viewComponent: 'VueDfDataPreviewView',
  category: 'Data',
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfDataPreviewView.name, VueDfDataPreviewView)
    Vue.component(VueDfDataPreviewEdit.name, VueDfDataPreviewEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
