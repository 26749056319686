import EntityRenderer from './EntityRenderer'
import ConnectorRenderer from './ConnectorRenderer'
import PropertyRenderer from './PropertyRenderer'
import ContainerRenderer from './ContainerRenderer'

export default {
  __init__: [
    'entityRenderer',
    'connectorRenderer',
    'propertyRenderer',
    'containerRenderer'
  ],
  entityRenderer: [ 'type', EntityRenderer ],
  connectorRenderer: [ 'type', ConnectorRenderer ],
  propertyRenderer: [ 'type', PropertyRenderer ],
  containerRenderer: ['type', ContainerRenderer]
}
