<template>
  <div>
    <h3>Type System Editor</h3>
  </div>
</template>

<script>
export default {
  name: 'TypeSystemEditor',
  components: {
  },
}
</script>
