<template>
  <div>
    <div class="row-data-view">
      <div class="row">
        <div class="col">
          <b-table striped bordered hover :items="value" ref="table">
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import typeViewMixin from "./typeViewMixin";
  import {BTable} from 'bootstrap-vue'

  export default {
    name: "RowTypeView",
    components: {BTable},
    mixins: [typeViewMixin]
  }
</script>

<style scoped>

</style>
