<template>
  <b-tabs>
    <b-tab title="Data Definition">
      <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
    </b-tab>
    <b-tab title="Chart Definition" @click="onChartDefinitionClick">
      <chart-definition v-if="renderChart" :config="chartConfig" :data="chartData" @chartconfigupdated="chartConfigUpdated"></chart-definition>
    </b-tab>
  </b-tabs>

</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
  const ChartDefinition =()=>import("./ChartDefinition");
  import { BTabs, BTab } from 'bootstrap-vue'
  export default {
    name: "VueDfChartingEdit",
    components: {ChartDefinition, BTabs, BTab},
    mixins: [widgetComponentEditMixins],
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    data(){
      return {
        renderChart: true
      }
    },
    computed:{
      chartConfig: function(){
        return this.model.config.chart.config;
      },
      chartData: function(){
        return this.model.config.chart.data;
      }
    },
    /*watch: {
      'model.config.data': {
        handler: function(newVal, oldVal){
          this.updateChartModel();
        },
        deep: true
      }
    },*/
    methods: {
      onChartDefinitionClick: function(){
        const that = this;
        this.renderChart = false;
        this.$nextTick(function(){
          that.renderChart = true;
        });
      },
      chartConfigUpdated: function(config){
        this.model.config.chart.config = config;
      },
      updateChartModel: function(){
       // this.model.config.chart.data = this.model.config.data;
      },
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.form[0].tabs.splice(1, 1);
        this.schemaForm = schemaFormSourceDestination;
      }
    }
  }
</script>

<style scoped>

</style>
