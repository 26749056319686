const serviceManager = {

  add: function (Vue, serviceItem) {
    Vue.services[serviceItem.service.name] = serviceItem.service;

    let itemOptions = serviceItem.options || {};
    itemOptions.services = Vue.services;
    itemOptions.router = itemOptions.router || Vue.router;
    itemOptions.eventBus = itemOptions.eventBus || Vue.dashboardBus;
    serviceItem.service.install(Vue, itemOptions)
  },
  install: function (Vue, options) {
    const services = {};
    const that = this;
    Vue.services = services;

    if (options.services) {
      options.services.forEach(function (item) {
        that.add(Vue, item);
      });
    }
    Vue.serviceManager = this;

    Object.defineProperty(Vue.prototype, '$services', {
      get: function () {
        return services;
      }
    })
  }
}

export default serviceManager;
