import VueDfMarkdownEdit from './VueDfMarkdownEdit'
import VueDfMarkdownView from './VueDfMarkdownView'

let widget = {
  type: 'VueDfMarkdown',
  title: 'Markdown',
  description: 'Configure Markdown widget',
  editComponent: 'VueDfMarkdownEdit',
  viewComponent: 'VueDfMarkdownView',
  category: 'Editor',
  editableSettings:{
    editable: true
  },
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfMarkdownView.name, VueDfMarkdownView)
    Vue.component(VueDfMarkdownEdit.name, VueDfMarkdownEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
