<template>
  <component-base v-bind:field-form="fieldForm">
    <query-builder :config="queryConfig" v-model="query">
      <template #groupOperator="props">
        <group-operator-slot :group-ctrl="props"></group-operator-slot>
      </template>
      <template #groupControl="props">
        <group-control-slot :group-ctrl="props"/>
      </template>
<!--      <template #rule="props">
        <rule-slot :rules="queryConfig.rules" :ruleCtrl="props"/>
      </template>-->
    </query-builder>
  </component-base>
</template>

<script>
import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'
/*import VueQueryBuilder from "./VueQueryBuilder";*/
import NumberComparisonRule from './components/NumberComparisonRule'
import QueryBuilder from "query-builder-vue";
import isEqual from 'lodash/isEqual'
import GroupOperatorSlot from "./slots/GroupOperatorSlot";
import GroupControlSlot from "./slots/GroupControlSlot";
import RuleSlot from "./slots/RuleSlot";

export default {
  name: "SfQueryBuilder",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  components: {QueryBuilder, NumberComparisonRule, GroupOperatorSlot, GroupControlSlot, RuleSlot},
  data() {
    return {
      query: null,
      defaultOperators: [
        {
          name: 'AND',
          identifier: 'AND',
        },
        {
          name: 'OR',
          identifier: 'OR',
        }
      ],
      defaultRules: [
        {
          identifier: "num",
          name: "Number Comparison",
          component: NumberComparisonRule,
          initialValue: () => ({
            lft: 0,
            rgt: 0,
            operator: "="
          })
        }
      ],
      defaultColors: ['hsl(88, 50%, 55%)', 'hsl(187, 100%, 45%)']
    }
  },
  watch: {
    query: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, this.localValue)) {
          this.localValue = newVal;
        }
      },
      deep: true
    }
  },
  created() {
    if (this.localValue) {
      this.query = this.localValue;
    }
  },
  computed: {
    config() {
      return this.fieldForm.config || {}
    },
    operators() {
      return this.config.operators || this.defaultOperators;
    },
    rules() {
      console.log('resolving rules', this.config.rules, this.defaultRules);
      return this.config.rules || this.defaultRules;
    },
    colors() {
      return this.config.colors || this.defaultColors;
    },
    queryConfig() {
      return {
        operators: this.operators,
        rules: this.rules,
        colors: this.colors
      }
    }
  }
}
</script>

<style scoped>

.query-builder-group {
  border: 1px solid #cdcdcd;
}

.query-builder-group ::v-deep .query-builder-child {
  border: 1px solid #dadde08c;
}

.query-builder-group ::v-deep .query-builder-group__child:not(:last-child) {
  margin-bottom: 0px;
}
</style>
