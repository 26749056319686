<template>
  <div class="bpmn-process-execution-container" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <div class="bpmn-process-execution-holder">
      <div class="small ml-2">
        Each process (instance) has states: {preStart, started, running, waiting, completed, canceled}
        <hr/>
        There could be multiple starts
      </div>
    </div>
  </div>

</template>

<script>
import widgetComponentViewMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins";

export default {
  name: "VueDfBpmnProcessView",
  mixins: [widgetComponentViewMixins],
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>

</style>
