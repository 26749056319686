var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c("div", { staticClass: "table-wrapper" }, [
        _c(
          "div",
          { staticClass: "table-container" },
          [
            _c("head", { domProps: { innerHTML: _vm._s(_vm.styles) } }),
            _c("v-runtime-template", {
              ref: "templateTable",
              attrs: { template: _vm.template },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }