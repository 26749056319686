<template>
  <div class="charting-container" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <vue-df-e-chart-wrapper
      :allowedResizeDirections="allowedResizeDirections"
      :chart-config="chartConfig"
      :dataset="chartDataset"
      :dimensions="chartDimensions"
      :height="contentHeight"
      :width ="'100%'"

      :resizable="contentResizable"
      @onresize="onResize" ref="chartWrapper"
      v-if="renderChart"/>
  </div>
</template>
<script>
  import chartingUtils from './chartingUtils'
  const VueDfEChartWrapper = () => import("../shared/VueDfEChartWrapper");
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

  export default {
    name: "VueDfChartingView",
    components: {VueDfEChartWrapper},
    mixins: [widgetComponentViewMixins],
    data() {
      return {
        renderChart: true,
        resizable: false,
        chartConfig: null,
        clientHeight: 0
      }
    },
    created(){
      this.$on('bv::modal::hide', this.onStructureChanged)
    },
    beforeDestroy() {
      this.$off('bv::modal::hide', this.onStructureChanged)
    },
    mounted(){
     // console.log('this.$parent.$el', this.$parent);
      this.clientHeight = this.$parent.$el.clientHeight;

      this.updateChart();
    },
    watch:{
      dataModel: {
        handler(newVal,oldVal){
          if (newVal!==oldVal){
            this.updateChart();
          }
        },
        deep: true
      }
    },
    methods: {
      updateChart(){
        const that = this;
        this.$nextTick(function(){
          const res = chartingUtils.safeEval(that.model.config.chart.config, that.dataModel, that.chart, { http: that.$http, jsulator: that.$jsulator}); // this.model.config.chart.config;
          that.chartConfig = res;
          console.log('Chart updated', that.chartConfig, that.model.config, that.dataModel);
        });
      },
      onStructureChanged: function(){
        console.log('bv::modal::hide');
        this.resizeChart();
      },
      onResize: function (data) {
        console.log('On Resized', data, this.model, this.dashboardEditMode);
        this.model.config.contentStyle = {
          defaultId: data
        };
      },
      resizeChart: function(){
        const that = this;
        this.renderChart = false;
        this.$nextTick(function () {
          that.renderChart = true;
        });
      },
      calcHeight(){
        /*if (this.clientHeight>300){
          return this.clientHeight;
        }*/
        return "auto";
      }
    },

    computed: {
      contentResizable: function(){
        return this.dashboardEditMode;
      },
      contentHeight: function () {
        const contentStyle = this.model.config.contentStyle;
        console.log('contentHeight in ChartingView', contentStyle);
        let height = this.calcHeight();
        if (contentStyle && contentStyle.defaultId && contentStyle.defaultId.height) {
          height =  contentStyle.defaultId.height;
        }

        if (this.isMaximized && this.maximizedArea){
          height = this.maximizedArea.height-120;
        }else if (height==='auto'){
          height = 300;
        }

        console.log('chart view contentHeight', height);
        return height;
      },
      allowedResizeDirections: function () {
        return this.dashboardEditMode ? ['b'] : [];
      },
      chart: function () {
        return this.$refs.chartWrapper ? this.$refs.chartWrapper.$refs.eChart : {};
      },
      chartDataset: function () {
        return null;
      },
      chartDimensions: function () {
        return null;
      }
    }
  }
</script>

<style scoped>
  /**
   * The default size is 600px×400px, for responsive charts
   * you may need to set percentage values as follows (also
   * don't forget to provide a size for the container).
   */
  /*.echarts {
    width: 100%;
    min-height: 200px;
  }*/
  .charting-container {
    height: 100%;
  }
</style>
