var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-sidebar",
    {
      attrs: {
        visible: _vm.showSidebar,
        title: "Entities",
        right: "",
        shadow: "",
        backdrop: "",
      },
      on: {
        change: function ($event) {
          return _vm.changed($event)
        },
      },
    },
    [
      _vm.flow !== null
        ? _c("div", { staticClass: "px-3 py-2" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Type")]),
                  _c("th", [_vm._v("ID")]),
                  _c("th", [_vm._v("Desc")]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.flow.entities, function (entity) {
                  return _c("tr", { key: entity.id }, [
                    _c("td", [_vm._v(_vm._s(entity.type))]),
                    _c("td", [_vm._v(_vm._s(entity.id))]),
                    _c("td", [_vm._v(_vm._s(entity.description))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }