var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c("div", { staticClass: "bpmn-process-execution-container" }, [
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bpmn-process-execution-holder" }, [
      _c("div", { staticClass: "small ml-2" }, [
        _vm._v(
          "\n      Each process (instance) has states: {preStart, started, running, waiting, completed, canceled}\n      "
        ),
        _c("hr"),
        _vm._v("\n      There could be multiple starts\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }