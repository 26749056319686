<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'

  export default {
    name: "VueDfTemplateEdit",
    mixins: [widgetComponentEditMixins],
    data() {
      return {
        schemaFormCustom: {
          schemaProperties: {
            template: {
              type: 'string',
              title: 'Template',
              description: 'Define Vue template to be used to render the view'
            },
            templateStyling: {
              type: 'string',
              title: 'Styling',
              description: 'Define css styles to be used in template'
            },
            templateFunctions: {
              type: 'string',
              title: 'Functions',
              description: 'Define Functions to be used in template'
            }
          },
          formItem: {
            title: 'Template Editor',
            items: [
              {
                type: 'tabs',
                tabs: [
                  {
                    title: 'Template',
                    items: [
                      {
                        style: 'min-height:400px;',
                        key: 'template',
                        type: 'ace',
                        config: {lang: 'html'}
                      }
                    ]
                  },
                  {
                    title: 'Styling',
                    items: [
                      {
                        key: 'templateStyling',
                        style: 'min-height:400px;',
                        type: 'ace',
                        config: {lang: 'css'}
                      }
                    ]
                  },
                  {
                    title: 'Functions',
                    items: [
                      {
                        key: 'templateFunctions',
                        style: 'min-height:400px;',
                        type: 'ace',
                        config: {lang: 'javascript'}
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    methods: {
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.schema.properties.template = this.schemaFormCustom.schemaProperties.template;
        schemaFormSourceDestination.schema.properties.templateStyling = this.schemaFormCustom.schemaProperties.templateStyling;
        schemaFormSourceDestination.schema.properties.templateFunctions = this.schemaFormCustom.schemaProperties.templateFunctions;
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);
        const updatedEditables = this.updateEditableParts(schemaFormSourceDestination);
        this.schemaForm = updatedEditables;

      }
    }
  }
</script>

<style scoped>

</style>
