
import VueDfMarkdown from './components/vueDfComponents/VueDfMarkdown'
import VueDfSchemaForm from './components/vueDfComponents/VueDfSchemaForm'
import VueDfTable from './components/vueDfComponents/VueDfTable'

import VueDfCharting from './components/vueDfComponents/VueDfCharting'
import VueDfFeed from './components/vueDfComponents/VueDfFeed'

import VueDfTemplate from './components/vueDfComponents/VueDfTemplate'
import VueDfWidgetTemplate from './components/vueDfComponents/VueDfWidgetTemplate'

import VueDfDataSource from './components/vueDfComponents/VueDfDataSource'
import VueDfDataPreview from './components/vueDfComponents/VueDfDataPreview'
import chartBuilder from './components/chartBuilder'

const vueDashboardFrameworkComponentsPlugin = {
  registerPlugins(Vue, options) {
    Vue.use(VueDfCharting)
    Vue.use(VueDfMarkdown)
    Vue.use(VueDfSchemaForm)
    Vue.use(VueDfTable)
    Vue.use(VueDfFeed)
    Vue.use(VueDfTemplate)
    Vue.use(VueDfWidgetTemplate)
    Vue.use(VueDfDataSource)
    Vue.use(VueDfDataPreview)

    Vue.use(chartBuilder)
  },
  install(Vue, options) {

    if (!Vue.__vueDfRegistered) {
      this.registerPlugins(Vue, options);
      this.__vueDfRegistered = true;
    }
  }
}

export default vueDashboardFrameworkComponentsPlugin;
