var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "component-base",
    { attrs: { "field-form": _vm.fieldForm } },
    [
      _vm.fieldForm.options && _vm.fieldForm.options.isEditor
        ? _c("bpmn-editor", {
            class: _vm.fieldForm.htmlClass,
            style: _vm.fieldForm.style,
            attrs: { "panel-options": _vm.fieldForm.options },
            model: {
              value: _vm.diagramData,
              callback: function ($$v) {
                _vm.diagramData = $$v
              },
              expression: "diagramData",
            },
          })
        : _vm._e(),
      !_vm.fieldForm.options || !_vm.fieldForm.options.isEditor
        ? _c("bpmn-viewer", {
            class: _vm.fieldForm.htmlClass,
            style: _vm.fieldForm.style,
            attrs: { "panel-options": _vm.fieldForm.options },
            model: {
              value: _vm.diagramData,
              callback: function ($$v) {
                _vm.diagramData = $$v
              },
              expression: "diagramData",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }