var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "table-container" }, [
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
              _vm._l(_vm.fields, function (field) {
                return _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(field.label)),
                ])
              }),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.data, function (rowItem, index) {
              return _c(
                "tr",
                { key: index },
                [
                  _c(
                    "th",
                    {
                      attrs: { scope: "row" },
                      on: {
                        click: function ($event) {
                          return _vm.onDataRowClick(index, rowItem)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "0.6em" } }, [
                        _c("i", {
                          class: [
                            "fas",
                            { "fa-plus": index !== _vm.currentOpenRowIndex },
                            { "fa-minus": index === _vm.currentOpenRowIndex },
                          ],
                        }),
                      ]),
                    ]
                  ),
                  _vm._l(rowItem, function (value, prop) {
                    return _c("td", [
                      _vm._v("\n          " + _vm._s(value) + "\n        "),
                    ])
                  }),
                ],
                2
              )
            }),
            _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
              _vm.currentOpenRowIndex > -1
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "row-data-item-details" },
                        [
                          _c("builder-component", {
                            attrs: {
                              "field-mapper": _vm.fieldMapper,
                              form: _vm.fieldForm.items,
                              "full-model": _vm.fullModel,
                              "is-droppable": _vm.isDroppable,
                              "merged-form":
                                _vm.fieldFormArray[_vm.currentOpenRowIndex],
                              "parent-form": _vm.fieldForm,
                              "schema-form-service": _vm.schemaFormService,
                              schema: _vm.fieldSchema.schema.items,
                              value: _vm.resolveRowValue(
                                _vm.currentOpenRowItem
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm",
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.appendToArray(_vm.resolveMaxItems(_vm.fieldForm))
              },
            },
          },
          [_vm._v("New")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }