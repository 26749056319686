//import Vue from 'vue'

let service = {
  name: 'storage',
  storage:{},
  loadModels: function (key, next) {
    let models = this.storage.get(key) || [];
    next(models);
  },
  saveModels: function (key, models, next) {
    this.storage.set(key, models);
    next({data: models});
  },
  install(Vue, options) {
    this.storage = Vue.ls;
    const that =this;
    Object.defineProperty(Vue.prototype, '$storage', {
      get: function () {
        return that.storage;
      }
    })
  }
}

export default service;
