import VueDfBpmnProcessView from './VueDfBpmnProcessView'
import VueDfBpmnProcessEdit from './VueDfBpmnProcessEdit'

let widget = {
  type: 'VueDfBpmnProcess',
  title: 'BPMN Process',
  description: 'BPMN Process',
  category: 'BPMN',
  editComponent: 'VueDfBpmnProcessEdit',
  viewComponent: 'VueDfBpmnProcessView',
  dashboards:[],
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfBpmnProcessView.name, VueDfBpmnProcessView)
    Vue.component(VueDfBpmnProcessEdit.name, VueDfBpmnProcessEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
