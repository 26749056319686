<template>
  <b-tabs>
    <b-tab title="Data Definition">
      <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
    </b-tab>
    <b-tab title="Markdown Definition">
      <vue-ace-markdown-editor v-model="contentModel"></vue-ace-markdown-editor>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
const VueAceMarkdownEditor = () => import('vue-json-schema-form-editor/src/aceMarkdown/VueAceMarkdownEditor')
import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
export default {
  name: "VueDfMarkdownEdit",
  mixins: [widgetComponentEditMixins],
  components: {VueAceMarkdownEditor, BTabs, BTab},
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  },
  computed:{
    contentModel: {
      get() {
        const content = this.model.config.markdown; //this.$jsulator.evaluate('TO_STRING($config.markdown$)', this.model);
        console.log('markdown content', content);
        return content;
      },
      set(val) {
        this.model.config.markdown=val;
        console.log('markdown content set', this.model.config.markdown);
      }
    }
  },
  methods:{
    mergeSchemaFormWithCustom: function () {
      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
      schemaFormSourceDestination.form[0].tabs.splice(1, 1);
      this.schemaForm = schemaFormSourceDestination;
    }
  }

}
</script>

<style scoped>

</style>
