<template>
  <!--<schema-form :form="form" :schema="schema" @validationResult="onValidationResult"
               v-model="model.data"></schema-form>-->

  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'

export default {
  name: "VueDfTableEdit",
  mixins: [widgetComponentEditMixins],
  data() {
    return {
      schemaFormCustom: {
        schemaProperties: {
          useFieldsFromResults: {
            type: "boolean",
            title: "Use Fields from Result",
            description: "Do you want to use fields from the results array. First object will be used and Column names will be extracted based on property name",
            default: false
          },
          tableDefinition: {
            type: 'object',
            properties: {
              striped: {
                type: 'boolean',
                title: 'Striped'
              },
              bordered: {
                type: 'boolean',
                title: 'Bordered'
              },
              borderless: {
                type: 'boolean',
                title: 'Borderless'
              },
              outlined: {
                type: 'boolean',
                title: 'Outlined'
              },
              small: {
                type: 'boolean',
                title: 'Small'
              },
              hover: {
                type: 'boolean',
                title: 'Hover'
              },
              dark: {
                type: 'boolean',
                title: 'Dark'
              },
              fixed: {
                type: 'boolean',
                title: 'Fixed'
              },
              footClone: {
                type: 'boolean',
                title: 'Foot Clone'
              },
              noBorderCollapse: {
                type: 'boolean',
                title: 'No Border Collapse'
              },
              tableVariant: {
                type: 'string',
                title: 'Table Variant',
                enum: ['success', 'primary', 'secondary', 'info', 'warning', 'danger', 'light', 'dark']
              },
              selectedRowVariant:{
                type: 'string',
                title: 'Selected Row Variant',
                enum: ['success', 'primary', 'secondary', 'info', 'warning', 'danger', 'light', 'dark']
              },
              responsive: {
                type: 'string',
                title: 'Responsive',
                enum: ['true', 'sm', 'md', 'lg', 'xl']
              },
              stickyHeader: {
                type: 'boolean',
                title: 'Sticky Header'
              },
              showCaption: {
                type: 'boolean',
                title: 'Show Caption'
              },
              captionHtml: {
                type: 'string',
                title: 'Caption'
              },
              captionTop: {
                type: 'boolean',
                title: 'Caption top'
              },
              headVariant: {
                type: 'string',
                title: 'Header Variant',
                enum: ['light', 'dark']
              },
              footVariant: {
                type: 'string',
                title: 'Footer Variant',
                enum: ['light', 'dark']
              },
              stacked: {
                type: 'boolean',
                title: 'Stacked'
              },
              tableBusyHtml: {
                type: 'string',
                title: 'Table Busy Template'
              },
              theadTopHtml: {
                type: 'string',
                title: 'THead Top',
                description: 'Define additional Top Header'
              },
              customFoot: {
                type: 'string',
                title: 'Footer Template',
                description: 'Define additional custom Footer template'
              },
              primaryKey: {
                type: 'string',
                title: 'Primary Key',
                description: 'Enter property name to be used as Primary Key in the table. It has to have unique values'
              },
              rowDetails: {
                type: 'string',
                title: 'Row Details',
                description: 'Define template to show details in the row. "row" property contains: item, index, fields[], toggleDetails()'
              }
            }
          },
          fields: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  title: 'Key',
                  description: 'Key that will match object property name in result lists'
                },
                label: {
                  type: 'string',
                  title: 'Label',
                  description: 'Label in the header column'
                },
                class: {
                  type: 'string',
                  title: 'CSS class'
                },
                stickyColumn: {
                  type: 'boolean',
                  title: 'Sticky Column',
                  description: 'Is this sticky column?'
                },
                isRowHeader: {
                  type: 'boolean',
                  title: 'Row Header',
                  description: 'Render as TH'
                },
                sortable: {
                  type: 'boolean',
                  title: 'Sortable',
                  description: 'Allow the column to be sortable'
                },
                sortDirection: {
                  type: 'string',
                  title: 'Sort Direction',
                  description: 'Initial sorting direction',
                  enum: ['asc', 'desc']
                },
                variant: {
                  type: 'string',
                  title: 'Color',
                  description: 'Color column and header',
                  enum: ['active', 'info', 'success', 'danger', 'warning']
                },
                cellFormat: {
                  type: 'string',
                  title: 'Cell Format',
                  description: 'Define format of the cell. "data" property contains: index, item, value, unformatted, detailsShowing, toggleDetails, rowSelected'
                },
                headFormat: {
                  type: 'string',
                  title: 'Header Format',
                  description: 'Define format of the column header. "data" property contains: column, field[props], label, selectAllRows(),clearSelected()'
                },
                footFormat: {
                  type: 'string',
                  title: 'Header Format',
                  description: 'Define format of the column footer. "data" property contains: column, field[props], label, selectAllRows(),clearSelected()'
                }
              }
            }
          },
          tableEvents: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                eventType: {
                  type: 'string',
                  title: 'Event Type',
                  description: 'Select Table event',
                  enum: ['OnRowSelected', 'OnRowCommand']
                },
                eventPublish: {
                  type: "object",
                  properties: {
                    events: {
                      type: "array",
                      title: "{{ value.eventName }}",
                      description: "Define events to publish",
                      items: {
                        type: "object",
                        properties: {
                          eventName: {
                            type: "string",
                            title: "Publish Event Name",
                            description: "Define name for the event to publish"
                          },
                          contextObject: {
                            type: "string",
                            title: "Context Object",
                            description: "Use expression to define Context Object in json form",
                            default: "{}"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          pagination: {
            type: 'object',
            properties: {
              usePagination: {
                type: 'boolean',
                title: 'Use Pagination',
                description: 'Select if pagination should be used'
              },
              position: {
                type: 'string',
                title: 'Position',
                description: 'Select pagination position',
                enum: ['Top', 'Bottom', 'Both']
              },
              perPage: {
                type: 'integer',
                title: 'Per Page',
                description: 'Set number of rows per page to be shown',
                default: 5
              }

            }
          }
        },
        formItem: {
          title: 'Table Column Editor',
          items: [
            {
              type: 'tabs',
              tabs: [
                {
                  type: 'tab',
                  title: 'Definition',
                  items: [
                    {
                      type: 'fieldset',
                      title: 'Definition',
                      key: 'tableDefinition',
                      items: [
                        {
                          type: 'section',
                          sectionContainerClass: "row",
                          sectionChildClass: "col",
                          items: [
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.striped',
                                'tableDefinition.bordered'
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.borderless',
                                'tableDefinition.outlined'
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.small',
                                'tableDefinition.hover'
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.dark',
                                'tableDefinition.fixed',
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.noBorderCollapse',
                                'tableDefinition.responsive'
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.stickyHeader',
                                'tableDefinition.footClone',
                              ]
                            },
                            {
                              type: 'section',
                              items: [
                                'tableDefinition.stacked'
                              ]
                            }

                          ]
                        },
                        {
                          type: 'section',
                          sectionContainerClass: "row",

                          items: [
                            {
                              type: 'section',
                              sectionType: 'fieldset',
                              title: 'Caption',
                              childClass:'col',
                              items: [
                                {
                                  type: 'section',
                                  sectionContainerClass: "row",
                                  sectionChildClass: "col",
                                  items: ['tableDefinition.showCaption', 'tableDefinition.captionTop']
                                },
                                {
                                  key: 'tableDefinition.captionHtml',
                                  type: 'ace',
                                  style: 'min-height:130px;'
                                }
                              ]
                            },
                            {
                              type: 'section',
                              sectionType: 'fieldset',
                              title: 'Table Busy',
                              childClass:'col',
                              items: [
                                {
                                  key: 'tableDefinition.tableBusyHtml',
                                  type: 'ace',
                                  style: 'min-height:200px;'
                                }
                              ]
                            },
                            {
                              type: 'section',
                              sectionContainerClass: "row",
                              sectionChildClass: "col",
                              sectionType: 'fieldset',
                              title: 'Variants',
                              childClass: 'col-5',
                              items: [
                                'tableDefinition.tableVariant',
                                'tableDefinition.headVariant',
                                'tableDefinition.footVariant',
                                'tableDefinition.selectedRowVariant'
                              ]
                            }
                          ]
                        },
                        {
                          type: 'section',
                          sectionContainerClass: "row",
                          sectionChildClass: "col",
                          items: [
                            {
                              type: 'section',
                              sectionType: 'fieldset',
                              title: 'Header Top Template',
                              items: [
                                {
                                  key: 'tableDefinition.theadTopHtml',
                                  type: 'ace',
                                  style: 'min-height:500px;',
                                  config:{ lang: 'html'}
                                }
                              ]
                            },
                            {
                              type: 'section',
                              sectionType: 'fieldset',
                              title: 'Footer Template',
                              items: [
                                {
                                  key: 'tableDefinition.customFoot',
                                  type: 'ace',
                                  style: 'min-height:500px;',
                                  config:{ lang: 'html'}
                                }
                              ]
                            },
                            {
                              type: 'section',
                              sectionType: 'fieldset',
                              title: 'Row Details Template',
                              items: [
                                {
                                  key: 'tableDefinition.rowDetails',
                                  type: 'ace',
                                  style: 'min-height:500px;',
                                  config:{ lang: 'html'}
                                }
                              ]
                            }
                          ]
                        }

                      ]
                    }
                  ]
                },
                {
                  type: 'tab',
                  title: 'Fields',
                  items: [
                    'useFieldsFromResults',
                    {
                      type: "section",
                      sectionType: 'fieldset',
                      title: 'Columns Definition',
                      condition: '!model.useFieldsFromResults',
                      items: [
                        {
                          key: "fields",
                          type: "tabarray",
                          add: "New",
                          remove: "Delete",
                          btnStyle: {
                            remove: "btn-danger"
                          },
                          title: "{{ value.key || 'Tab '+$index }}",
                          items: [
                            {
                              type: 'section',
                              sectionContainerClass: "row",
                              sectionChildClass: "col",
                              items: [
                                'fields[].key',
                                'fields[].label',
                              ]
                            },
                            {
                              type: 'section',
                              sectionContainerClass: "row",
                              sectionChildClass: "col",
                              items: [
                                'fields[].sortable',
                                {
                                  key: 'fields[].variant',
                                  type: 'radiosinline'
                                }
                              ]
                            },
                            {
                              type: 'section',
                              sectionContainerClass: "row",
                              sectionChildClass: "col",
                              items: [
                                {
                                  key: 'fields[].cellFormat',
                                  type: 'ace',
                                  style: 'min-height:100px;'
                                },
                                {
                                  key: 'fields[].headFormat',
                                  type: 'ace',
                                  style: 'min-height:100px;'
                                },
                                {
                                  key: 'fields[].footFormat',
                                  type: 'ace',
                                  style: 'min-height:100px;'
                                }
                              ]
                            }

                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'tab',
                  title: 'Table Events',
                  items: [

                    {
                      type: 'section',
                      title: 'Table Events',
                      sectionType: 'fieldset',
                      items: [
                        {
                          key: 'tableEvents',
                          type: 'tabarray',
                          add: "New",
                          remove: "Delete",
                          btnStyle: {
                            remove: "btn-danger"
                          },
                          title: "{{ value.eventType || 'Tab '+$index }}",
                          items: [
                            'tableEvents[].eventType',
                            {
                              key: "tableEvents[].eventPublish",
                              type: 'fieldset',
                              title: 'Event Publish',
                              items: [
                                {
                                  key: 'tableEvents[].eventPublish.events',
                                  type: 'tabarray',
                                  add: "New",
                                  remove: "Delete",
                                  btnStyle: {
                                    remove: "btn-danger"
                                  },
                                  title: "{{ value.eventName || 'Tab '+$index }}",
                                  items: [
                                    'tableEvents[].eventPublish.events[].eventName',
                                    {
                                      key: 'tableEvents[].eventPublish.events[].contextObject',
                                      type: 'ace',
                                      title: 'Context Object',
                                      style: 'min-height:200px;'
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'tab',
                  title: 'Pagination',
                  items: [
                    {
                      type: 'section',
                      title: 'Table Pagination',
                      sectionType: 'fieldset',
                      items: [
                        {
                          key: 'pagination.usePagination'
                        },
                        {
                          type: 'section',
                          title: 'Settings',
                          sectionType: 'fieldset',
                          condition: 'model.pagination.usePagination',
                          items:[
                            {
                              type: 'section',
                              sectionContainerClass: 'row',
                              sectionChildClass: 'col',
                              items:[
                                {
                                  key: 'pagination.position'
                                },
                                {
                                  key: 'pagination.perPage'
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
    }
  },
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  },
  methods: {
    mergeSchemaFormWithCustom: function () {
      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
      schemaFormSourceDestination.form[0].tabs.splice(1, 1);
      for (const prop in this.schemaFormCustom.schemaProperties) {
        schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
      }
      schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);


      this.schemaForm = schemaFormSourceDestination;

    }
  }
}
</script>

<style scoped>

</style>
