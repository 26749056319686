<template>
  <div class="containers">
    TEST 1
    <div ref="canvas" class="canvas"></div>
  </div>
</template>

<script>
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
export default {
  name: "BpmnViewer",
  props: {
    value: String,

  },
  data(){
    return {
      xmlData: '',
      modeler: null
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.xmlData) {
          this.openDiagram(newVal);
        }
      }
    }
  },
  async mounted() {
    this.modeler = new BpmnViewer({
      container: this.$refs["canvas"]
    });
    if (this.xmlData) {
      this.openDiagram(this.xmlData);
    }
  },
  methods:{
    openDiagram(xml) {
      return new Promise(async (resolve, reject) => {
        if (xml) {
          try {
            const result = await this.modeler.importXML(xml);
            //console.log('rendered');
            resolve()
          } catch (err) {
            reject(err);
          }
          this.xmlData = xml;
        } else {
          this.modeler.createDiagram();
          setTimeout(() => {
            let modelerCanvas = this.modeler.get("canvas");
            let rootElement = modelerCanvas.getRootElement();
            let modeling = this.modeler.get("modeling");

            rootElement.children.forEach(n => {
              if (n.type === 'bpmn:StartEvent') {
                modeling.updateProperties(n, {
                  name: 'Start Event',
                });
              } else if (n.type === 'bpmn:EndEvent') {
                modeling.updateProperties(n, {
                  name: 'End Event',
                });
              }
            })
            resolve();
          });
        }
      })
    },
  }
}
</script>

<style scoped>

.containers {
  background-color: #ffffff;
  height: calc(100vh - 165px);
  display: flex;
}


.canvas {
  width: 100%;
  height: 100%;
}

</style>
