var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    [
      _c(
        "b-tab",
        { attrs: { title: "Data Definition" } },
        [
          _c("schema-form", {
            attrs: { form: _vm.resolvedForm, schema: _vm.resolvedSchema },
            model: {
              value: _vm.model.config.data,
              callback: function ($$v) {
                _vm.$set(_vm.model.config, "data", $$v)
              },
              expression: "model.config.data",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tab",
        { attrs: { title: "Markdown Definition" } },
        [
          _c("vue-ace-markdown-editor", {
            model: {
              value: _vm.contentModel,
              callback: function ($$v) {
                _vm.contentModel = $$v
              },
              expression: "contentModel",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }