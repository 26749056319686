<template>
  <div class="widget-schema-form-wrapper" v-if="(dashboardEditMode || widgetSettings.widgetVisible)">
<!--      <vue-element-loading :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-screen="false"></vue-element-loading>  -->

    <div class="widget-schema-form">
      <schema-form ref="schemaForm" :form="resolveForm()" :schema="resolveSchema()" @validationResult="onValidationResult"
                   v-model="dataModel"></schema-form>
    </div>
  </div>
</template>

<script>
import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

export default {
  name: "VueDfSchemaFormView",
  mixins: [widgetComponentViewMixins],
  methods: {
    resolveSchema: function () {
      return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.schema : {};
    },
    resolveForm: function () {
      return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.form : [];
    },
    onValidationResult: function (value) {
      //console.log('vuedfschema form onValidationResult value', value);
      //console.log('vuedfschema form onValidationResult dataModel', this.dataModel);
      this.validationResult = value;

      if (value.valid) {
        this.onSubmit(this.dataModel);
      } else {
        this.notifyVueMessage('Form is not valid', 'bottom', 'right', 'danger', 10000);
        console.log('Not valid form', this.validationResult);
      }
    },
    getValidateDataModel: function () {

      const validatedForm = this.$refs['schemaForm'].validateForm();
      console.log('call in schemaFormView', validatedForm, this.dataModel);

      if (validatedForm && validatedForm.valid){
        return this.dataModel;
      }else{
        validatedForm.message = 'Form is not valid!!!'
        return validatedForm;
      }
    }
  }
}
</script>

<style scoped>

.widget-schema-form {
  padding: 0px 5px 0px 5px;
}
</style>
