var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data && _vm.data.length > 0
    ? _c(
        "div",
        { staticClass: "feed-view-wrapper" },
        [
          _vm.config.viewType === "MAGAZINE"
            ? [
                _c(
                  "ul",
                  { staticClass: "list-unstyled feed-view magazine" },
                  _vm._l(_vm.data, function (item) {
                    return _c("li", { key: item.link }, [
                      _c("div", { staticClass: "feed-item-container" }, [
                        _vm.resolveImage(item)
                          ? _c("div", {
                              staticClass: "feed-item-image",
                              style:
                                "background-image: url(" +
                                _vm.resolveImage(item) +
                                ")",
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "feed-item-content small" }, [
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-title",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "feed-item-meta text-muted" },
                            [
                              _c("span", [
                                _c("div", [
                                  _c("span", { staticClass: "author" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  item.categories && item.categories.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "categories" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.resolveCategories(item))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.resolveDate(item))),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  _c("span", { staticClass: "source" }, [
                                    _vm._v(_vm._s(_vm.resolveSource(item))),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "feed-item-summary" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.resolveItemSummary(item)),
                              },
                            }),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-visit",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v("Read on site")]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
          _vm.config.viewType === "MEDIUM"
            ? [
                _c(
                  "ul",
                  { staticClass: "list-unstyled feed-view medium" },
                  _vm._l(_vm.data, function (item) {
                    return _c("li", { key: item.link }, [
                      _c("div", { staticClass: "feed-item-container" }, [
                        _c("div", { staticClass: "feed-item-content small" }, [
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-title",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "feed-item-meta text-muted" },
                            [
                              _c("span", [
                                _c("div", [
                                  _c("span", { staticClass: "author" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  item.categories && item.categories.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "categories" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.resolveCategories(item))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.resolveDate(item))),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  _c("span", { staticClass: "source" }, [
                                    _vm._v(_vm._s(_vm.resolveSource(item))),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "feed-item-summary" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.resolveItemSummary(item)),
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
          _vm.config.viewType === "COMPACT"
            ? [
                _c(
                  "ul",
                  { staticClass: "list-unstyled feed-view compact" },
                  _vm._l(_vm.data, function (item) {
                    return _c("li", { key: item.link }, [
                      _c("div", { staticClass: "feed-item-container" }, [
                        _c("div", { staticClass: "feed-item-content small" }, [
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-title",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "feed-item-meta text-muted" },
                            [
                              _c("span", [
                                _c("div", [
                                  _c("span", { staticClass: "author" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  item.categories && item.categories.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "categories" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.resolveCategories(item))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.resolveDate(item))),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  _c("span", { staticClass: "source" }, [
                                    _vm._v(_vm._s(_vm.resolveSource(item))),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
          _vm.config.viewType === "FULL"
            ? [
                _c(
                  "ul",
                  { staticClass: "list-unstyled feed-view" },
                  _vm._l(_vm.data, function (item) {
                    return _c("li", { key: item.link }, [
                      _c("div", { staticClass: "feed-item-container full" }, [
                        _vm.resolveImage(item)
                          ? _c("div", {
                              staticClass: "feed-item-image",
                              style:
                                "background-image: url(" +
                                _vm.resolveImage(item) +
                                ")",
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "feed-item-content small" }, [
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-title",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "feed-item-meta text-muted" },
                            [
                              _c("span", [
                                _c("div", [
                                  _c("span", { staticClass: "author" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  item.categories && item.categories.length > 0
                                    ? _c(
                                        "span",
                                        { staticClass: "categories" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.resolveCategories(item))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.resolveDate(item))),
                                  ]),
                                  _vm._v(" //\n                  "),
                                  _c("span", { staticClass: "source" }, [
                                    _vm._v(_vm._s(_vm.resolveSource(item))),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "feed-item-summary" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.resolveItemSummary(item)),
                              },
                            }),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "feed-item-visit",
                              attrs: { target: "_blank", href: item.link },
                            },
                            [_vm._v("Read on site")]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }