<template>
  <component-base v-bind:field-form="fieldForm">
    <bpmn-editor v-if="fieldForm.options && fieldForm.options.isEditor" :class="fieldForm.htmlClass" :style="fieldForm.style" v-model="diagramData"
                 :panel-options="fieldForm.options"></bpmn-editor>
    <bpmn-viewer v-if="!fieldForm.options || !fieldForm.options.isEditor" :class="fieldForm.htmlClass" :style="fieldForm.style" v-model="diagramData"
                 :panel-options="fieldForm.options"></bpmn-viewer>

  </component-base>
</template>

<script>
import buildMixins from 'vue-json-schema-form/src/components/schemaForm/buildMixins'
import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'
import functionDiscoveryMixin from "vue-json-schema-form/src/components/schemaForm/functionDiscoveryMixin";
import BpmnEditor from "vue-diagram/src/bpmn/BpmnEditor";
import BpmnViewer from "vue-diagram/src/bpmn/BpmnViewer";

export default {
  name: "SfBpmnEditor",
  mixins: [buildMixins, componentMixin, simpleComponentMergeInMixin, functionDiscoveryMixin],
  components: {BpmnEditor, BpmnViewer},
  async created() {
    const that = this;
    if (this.$bus) {
      //this.$bus.$on('__lookupEvent', that.onEvent);
    }

    if (this.schemaFormService) {// is it better to subscribe on specific multiple events???
      //this.schemaFormService.subscribe(this.fieldForm.key, that.onEvent);
    }
  },
  beforeDestroy() {
    const that = this;
    if (this.$bus) {
      // this.$bus.$off('__lookupEvent', that.onEvent);
    }
    if (this.schemaFormService) {
      //this.schemaFormService.unsubscribe(this.fieldForm.key, that.onEvent);
    }
  },
  data() {
    return {
      diagramData: null
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        // console.log('diagramData value newVal changed', newVal, oldVal);
        this.diagramData = newVal;
      }
    },
    diagramData: {
      handler(newVal, oldVal) {
        //console.log('diagramData newVal changed', newVal, oldVal);
        if (newVal !== oldVal && newVal !== this.value) {
          this.$emit('input', newVal);
        }
      }
    }
  },
  computed: {
    /*diagramXML(){
      return this.diagramData?this.diagramData:null;
    }*/
  }
}
</script>

<style scoped>

</style>
