var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid group-ctrl-slot" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-auto" }, [
        _c(
          "div",
          {
            staticClass: "btn-group",
            attrs: {
              role: "group",
              "aria-label": "Button group with nested dropdown",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "btn-group",
                class: { show: _vm.expanded },
                attrs: { role: "group" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary dropdown-toggle",
                    attrs: {
                      id: "btnGroupDrop1",
                      type: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": String(_vm.expanded),
                    },
                    on: {
                      click: function ($event) {
                        _vm.expanded = !_vm.expanded
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.ruleName))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    class: { show: _vm.expanded },
                    attrs: { "aria-labelledby": "btnGroupDrop1" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.setRule("")
                          },
                        },
                      },
                      [_vm._v("Select a Rule")]
                    ),
                    _vm._l(_vm.groupCtrl.rules, function (rule) {
                      return _c(
                        "a",
                        {
                          key: rule.identifier,
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.setRule(rule.identifier)
                            },
                          },
                        },
                        [_vm._v(_vm._s(rule.name))]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: _vm.selectedRule === "" },
                on: { click: _vm.addRule },
              },
              [_vm._v("Add Rule")]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "col-auto" }, [
        _c("button", {
          staticClass: "btn",
          domProps: { textContent: _vm._s("Add Group") },
          on: { click: _vm.groupCtrl.newGroup },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }