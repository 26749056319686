var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "Data Type Definition" } },
            [
              _c("schema-form", {
                attrs: { form: _vm.resolvedForm, schema: _vm.resolvedSchema },
                on: { validationResult: _vm.onValidationResult },
                model: {
                  value: _vm.model.config.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.model.config, "data", $$v)
                  },
                  expression: "model.config.data",
                },
              }),
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "Data Preview" } }, [
            _c(
              "div",
              { staticClass: "data-preview p-2" },
              [
                _c("strong", [_vm._v(_vm._s(_vm.model.config.data.dataType))]),
                _c("hr"),
                _c(_vm.componentType, {
                  tag: "component",
                  attrs: { value: _vm.dataToPreview },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }