<template>
  <div class="template-wrapper"  v-if="dashboardEditMode || widgetSettings.widgetVisible">
<!--    <vue-element-loading :active.sync="isLoading"
                         :can-cancel="false"
                         is-full-page="false"></vue-element-loading>-->

    <div class="template-container" v-bind="dataId">
      <component :is="anotherComponentType" :meta="metaContextObject._meta" :model="model"
                 :options="templateOptions"
                 :dataModel="dataModel"
                 @emitevent="onEmitEvent"
                 v-bind="dataId"
                 v-if="reload"></component>
    </div>
  </div>
</template>

<script>
import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'


const VTemplateWrapper = () => import('./VTemplateWrapper')
const VueDfTemplateWrapper = () => import('./VueDfTemplateWrapper')

import dynamicCssManager from "vue-dashboard-framework/src/components/vuedf/utils/dynamicCssManager";

export default {
  name: "VueDfTemplateView",
  mixins: [widgetComponentViewMixins],
  components: {
    VTemplateWrapper, VueDfTemplateWrapper
  },
  data() {
    return {
      reload: true,
      componentType: 'v-template-wrapper',
      anotherComponentType: 'vue-df-template-wrapper',
      functions: {},
      template: this.model.config.data.template
    }
  },
  computed: {
    templateOptions: function () {
      return {
        template: this.template,
        dataModel: this.dataModel
      }
    }
    /*eventApi: function(){
      const that = this;
      const api = {
        publishObjectEvent(eventDef){
          that.publishObjectEvent(eventDef);
        }
      }
      return api
    }*/
  },
  methods: {
    loadTemplateCss: function (def) {
      const css = this.$jsulator.evaluate('$config.data.templateStyling$', def);
      //   console.log('Template styling css', css, def);
      if (null !== css && undefined !== css) {
        dynamicCssManager.appendChild(this, this.id, css);
      }
    },
    onEmitEvent: function (event) {
       console.log('Template emitted event', event);
      this.publishEvent(event.eventName, event);
    }
  }
}
</script>

<style scoped>
.template-container {
  background-color: transparent;
}

</style>
