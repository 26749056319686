import initPlugin from '../initPlugin'
import Vue from "vue";
import draggable from "vuedraggable";


const initPluginViewMixin = {
  beforeCreate() {
    Vue.component('draggable', draggable)

    Vue.use(initPlugin,{
      appConfig: this.$appConfig,
      router: this.$router
    });
  },
  beforeMount() {
    /*this.$services['vueDfApplicationService'].loadAllRoutes(function (routes) {
    });*/
  },
}

export default initPluginViewMixin;
