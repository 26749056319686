var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "component-base",
    { attrs: { "field-form": _vm.fieldForm } },
    [
      _c("query-builder", {
        attrs: { config: _vm.queryConfig },
        scopedSlots: _vm._u([
          {
            key: "groupOperator",
            fn: function (props) {
              return [
                _c("group-operator-slot", { attrs: { "group-ctrl": props } }),
              ]
            },
          },
          {
            key: "groupControl",
            fn: function (props) {
              return [
                _c("group-control-slot", { attrs: { "group-ctrl": props } }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }