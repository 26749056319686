<template>
  <div class="table-wrapper" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <!--    <vue-element-loading :active.sync="isLoading"
                             :can-cancel="false"
                             is-full-page="false"></vue-element-loading>-->

    <div class="table-container">
      <head v-html="styles"></head>
      <v-runtime-template ref="templateTable" :template="template"></v-runtime-template>
    </div>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'
import tableUtils from 'vue-json-schema-form-editor/src/tableEditor/tableUtils';
import {BTable, BPagination} from 'bootstrap-vue'


export default {
  name: "VueDfTableView",
  mixins: [widgetComponentViewMixins],
  components: {
    VRuntimeTemplate, BTable, BPagination
  },
  data() {
    return {
      selectable: true,
      selectMode: 'single',
      selected: [],
      previousSelected: null,
      previousSelectedIndex: -1,
      template: undefined,
      styles: '',
      pagination: {
        perPage: undefined,
        currentPage: 1,
        totalRows: this.totalRows
      }
    }
  },
  created() {

    this.pagination.perPage = this.$jsulator.evaluate('$config.data.pagination.perPage$', this.model);
    this.createTableTemplate();
    // console.log('Created Table');
  },
  watch: {
    dataModel: {
      handler(newVal, oldVal) {
        //console.log('Selected state', this.selected, this.previousSelected, this.previousSelectedIndex, this.id);
        if (this.previousSelected !== null) {
          this.selectPreviouslySelectedItem();
        } else {
          this.selectFirstItem();
        }
      },
      deep: true
    }
  },
  computed: {
    totalRows: function () {
      return this.data.length;
    },
    data: function () {
      // console.log('dataModel', this.dataModel);
      if (this.dataModel && Array.isArray(this.dataModel)) {
        this.pagination.totalRows = this.dataModel.length;
        return this.dataModel;
      }
      return [];
    },
    tableDef: function () {
      return this.model.config.data.tableDefinition || {};
    }
  },
  methods: {
    selectPreviouslySelectedItem() {
      /* if (this.data && this.previousSelected){
         let index = 0;
         for (let i = 0;i<this.data.length;i++){

         }
       }*/
      if (this.previousSelectedIndex > -1) {
        this.selectRow(this.previousSelectedIndex);
      }
    },
    selectFirstItem() {
      this.selectRow(0);
    },
    selectRow(rowIndex) {
     // console.log('Selecting row: ' + rowIndex, this.data, this.id);
      if (this.data && this.data.length > 0) {
        const templateTable = this.$refs.templateTable;
        if (templateTable && templateTable.$children && templateTable.$children[0] && templateTable.$children[0].$refs) {
          const tableWidgetId = tableUtils.getTableId(this.model.config.data, this.id);
          const that = this;
          this.$nextTick(function () {
            const tableRef = templateTable.$children[0].$refs;
            //   console.log('SELECT TABLE ROW', that.previousSelected, tableRef[tableWidgetId], tableRef[tableWidgetId].selectedRows, tableRef[tableWidgetId].selectedRows.length === 0);
            if (tableRef[tableWidgetId]) { // && !that.previousSelected
              const table = tableRef[tableWidgetId];
              table.selectRow(rowIndex);
            }
          });
        }
      }
    },
    createTableTemplate: function () {
      //  console.log('widget id: '+this.id);
      this.template = tableUtils.buildMarkup(this.model.config.data, this.id);
      //  console.log('Table template: '+this.template);
    },
    getTableEventDef: function (eventType) {
      const tableEvents = this.model.config.data.tableEvents;
      if (tableEvents) {
        tableEvents.forEach(function (tableEvent) {
          if (tableEvent.eventType === eventType)
            return tableEvent;
        });
      }
    },
    onRowCommand(item, command) {
      console.log('onRowCommand', item, command, arguments);
      if (item && command) {
        const tableEventDef = find(this.model.config.data.tableEvents, {eventType: 'OnRowCommand'});
        if (tableEventDef && tableEventDef.eventPublish.events) {
          this.executePublishEvents(tableEventDef.eventPublish, {item: cloneDeep(item), command: command});
        }
      }
    },
    determineActive(item, type) {
      if (item && type == 'row') {
       // console.log('ddd', item, type, this.selected, this.previousSelected, this.previousSelectedIndex, this.data, this.id);
        /*if (this.selectedRows.some(row => row.id == item.id)) {
          return 'table-active';
        }*/
        const index = this.data.indexOf(item);
      //  console.log('index', index, this.previousSelectedIndex, index===this.previousSelectedIndex, item===this.previousSelected);
        if (index===this.previousSelectedIndex){
          return 'table-active';
        }
      }
    },
    onRowSelected(items) {
      if (items && items.length > 0) {
        this.previousSelected = items[0];
        if (this.data) {
          this.previousSelectedIndex = this.data.indexOf(this.previousSelected);
        }
      }

     // console.log('onRowSelected', this.previousSelected, this.previousSelectedIndex, this.model, items, arguments);
      if (items && items.length) {
        const tableEventDef = find(this.model.config.data.tableEvents, {eventType: 'OnRowSelected'});
     //   console.log('OnRowSelected events to publish', tableEventDef);

        if (tableEventDef && tableEventDef.eventPublish.events) {
          this.executePublishEvents(tableEventDef.eventPublish, cloneDeep(items[0]));
        }
      }
    },
    resolveFields: function () {
      if (this.model.config.data) {
        if (!this.model.config.data.useFieldsFromResults && this.model.config.data.fields) {
          return this.model.config.data.fields;
        } else {
          return null;
        }
      }
      return null;
    }
  }
}
</script>

<style scoped>

</style>
