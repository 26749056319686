<template>

  <div>
    <div v-if="row.item.pages" class="p-3">
      <ul v-if="row.item.pages" class="list-group">
        <li v-for="page in row.item.pages" class="list-group-item">
          <div class="row">
            <div class="col-2">
	                 <span>
	                    {{ page.category }}
	                 </span>
            </div>
            <div class="col-10">
              <a :href="page.url" target="_blank">
                {{ page.url }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <button class="btn btn-sm btn-warning" @click="row.toggleDetails">Hide Pages</button>

  </div>

</template>

<script>
export default {
  name: "BTableRowTemplate",
  props: 'row'
}
</script>

<style scoped>

</style>
