<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>


<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'

  export default {
    name: "VueDfFeedEdit",
    mixins: [widgetComponentEditMixins],
    data() {
      return {
        schemaFormCustom1: {
          schemaProperties: {
            url: {
              type: 'string',
              title: 'URL',
              description: 'Enter RSS feed to monitor'
            },
            size: {
              type: 'integer',
              title: 'Size',
              description: 'Number of items to show'
            },
            viewType: {
              type: 'string',
              title: 'View Type',
              description: 'Select how to show content',
              enum: ['COMPACT', 'MEDIUM', 'MAGAZINE', 'FULL'],
              default: 'COMPACT'
            }
          },
          formItem: {
            title: 'Feed Definition',
            items: [
              {
                type: 'section',
                htmlClass: 'row',
                items: [
                  {
                    key: 'url',
                    htmlClass: 'col-6',
                    placeholder: 'Enter URL'
                  },
                  {
                    key: 'size',
                    htmlClass: 'col-3',
                    placeholder: 'Enter number of items to show'
                  },
                  {
                    key: 'viewType',
                    htmlClass: 'col-3',
                    placeholder: 'Select View Type'
                  }
                ]
              }
            ]
          }
        }
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    computed: {
      schemaFormCustom: function (){
        return this.model.schemaFormCustom || this.schemaFormCustom1; // for back compatibility - newly added widget will have this.model.schemaFormCustom available
      }
    },
    methods: {
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();

        for (const prop in this.schemaFormCustom.schemaProperties) {
          schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
        }
        schemaFormSourceDestination.form[0].tabs.splice(0, 5);
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

        this.schemaForm = schemaFormSourceDestination;

      }
    }
  }
</script>

<style scoped>

</style>
