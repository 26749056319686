var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("List of Flows")]),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.getFlows(), function (flow) {
          return _c(
            "tr",
            {
              key: `${flow.id}-${flow.idx}`,
              on: {
                click: function ($event) {
                  return _vm.openFlow(flow.id)
                },
              },
            },
            [
              _c("td", [_vm._v(_vm._s(flow.id))]),
              _c("td", [_vm._v(_vm._s(flow.description))]),
              _c("td", [_vm._v(_vm._s(flow.entities.length))]),
              _c("td", [_vm._v(_vm._s(flow.relations.length))]),
              _c("td", [_vm._v(_vm._s(flow.source))]),
              _c(
                "td",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.removeFlow(flow.id)
                        },
                      },
                    },
                    [_c("b-icon-x")],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("hr"),
    _c("h3", [_vm._v("Upload Flow")]),
    _c(
      "div",
      [
        _c("b-form-file", {
          attrs: { accept: "application/json", size: "sm" },
          on: { input: _vm.uploadFlow },
          model: {
            value: _vm.importFlowFile,
            callback: function ($$v) {
              _vm.importFlowFile = $$v
            },
            expression: "importFlowFile",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _c("th", [_vm._v("Description")]),
        _c("th", [_vm._v("Num Entities")]),
        _c("th", [_vm._v("Num Relations")]),
        _c("th", [_vm._v("Source")]),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }