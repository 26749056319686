import VueDfTemplateView from './VueDfTemplateView'
import VueDfTemplateEdit from './VueDfTemplateEdit'

import { ProgressPlugin } from 'bootstrap-vue'

let widget = {
  type: 'VueDfTemplate',
  title: 'Template',
  description: 'Render Template',
  category: 'Template',
  editComponent: 'VueDfTemplateEdit',
  viewComponent: 'VueDfTemplateView',
  frameless: false,
  editableSettings:{
    editable: true
  },
  config: {
    data:{
      template: '<div>This is default template content</div>'
    }
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfTemplateView.name, VueDfTemplateView)
    Vue.component(VueDfTemplateEdit.name, VueDfTemplateEdit)

    Vue.use(ProgressPlugin);

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
