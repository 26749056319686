<template>
  <div class="widget-markdown" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <vue-markdown-viewer :model="content"></vue-markdown-viewer>
  </div>
</template>

<script>
  import widgetComponentViewMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins";

  const VueMarkdownViewer =()=>import('vue-json-schema-form-editor/src/aceMarkdown/VueMarkdownViewer')
  export default {
    name: "VueDfMarkdownView",
    mixins: [widgetComponentViewMixins],
    components:{VueMarkdownViewer},
    data(){
      return {

      }
    },
    computed:{
      content: function(){
        /*let content =  this.resolveContextObject(this.model.config.markdown, this.dataModel, 'string');
        console.log('content markdown', content, this.model.config);
        return content;*/
        let content = this.model.config.markdown;
        if (this.dataModel.markdown){
          content = this.dataModel.markdown;
        }
        console.log('content markdown', content, this.dataModel);
        return content;

      }
      /*contentModel: {
        get()
        {
          const content = this.$jsulator.evaluate('TO_STRING($dataModel$)', this);
          console.log('markdown content', content);
          return content;
        },
        set(val){
          this.dataModel = val;
        }
      }*/
    }
  }
</script>

<style scoped>

</style>
