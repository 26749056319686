var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.lft,
          expression: "lft",
        },
      ],
      attrs: { type: "number" },
      domProps: { value: _vm.lft },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.lft = $event.target.value
        },
      },
    }),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.operator,
            expression: "operator",
          },
        ],
        staticClass: "ml8",
        attrs: { type: "select" },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.operator = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.operators, function (operator, idx) {
        return _c("option", { key: idx, domProps: { value: operator } }, [
          _vm._v(_vm._s(operator)),
        ])
      }),
      0
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.rgt,
          expression: "rgt",
        },
      ],
      staticClass: "ml8",
      attrs: { type: "number" },
      domProps: { value: _vm.rgt },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.rgt = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }