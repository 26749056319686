<template>

</template>

<script>
  export default {
    name: "VueDfWidgetTemplateEdit"
  }
</script>

<style scoped>

</style>
