var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c("div", { staticClass: "template-wrapper" }, [
        _c(
          "div",
          _vm._b(
            { staticClass: "template-container" },
            "div",
            _vm.dataId,
            false
          ),
          [
            _vm.reload
              ? _c(
                  _vm.anotherComponentType,
                  _vm._b(
                    {
                      tag: "component",
                      attrs: {
                        meta: _vm.metaContextObject._meta,
                        model: _vm.model,
                        options: _vm.templateOptions,
                        dataModel: _vm.dataModel,
                      },
                      on: { emitevent: _vm.onEmitEvent },
                    },
                    "component",
                    _vm.dataId,
                    false
                  )
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }