<template>
  <schema-form :schema="resolvedSchema" :form="resolvedForm" v-model="model.config.data"
               @validationResult="onValidationResult"></schema-form>

</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'

  export default {
    name: "VueDfSchemaFormEdit",
    mixins: [widgetComponentEditMixins],
    data() {
      return {
        schemaForm: {
          schema: {},
          form: []
        },
        schemaFormCustom: {
          schemaProperties: {
            schemaForm: {
              type: 'object',
              title: 'Form Editor',
              description: 'Use drag and drop features to build the form',
              default: {
                schema: {},
                form: []
              },
              properties: {}
            }
          },
          formItem: {
            title: 'Form Editor',
            items: [
              {
                key: 'schemaForm',
                type: 'schemaFormEditor',
                enableConfig: true,
                enableElements: true,
                editorClass: 'col',
                elementClass: 'col-2',
                configClass: 'col-3'
              }
            ]
          }
        }
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    methods: {
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.schema.properties.schemaForm = this.schemaFormCustom.schemaProperties.schemaForm;
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

        this.filterCustomEditable(schemaFormSourceDestination.form[0].tabs);

        this.schemaForm = schemaFormSourceDestination;

      },
      onValidationResult: function (value) {
      }
    }
  }
</script>

<style scoped>

</style>


