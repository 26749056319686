<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
import widgetComponentEditMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins";

export default {
  name: "VueDfBpmnProcessEdit",
  mixins: [widgetComponentEditMixins],
  data() {
    return {
      schemaFormCustom: {
        schemaProperties: {

        },
        formItem: {

        }
      }
    }
  },
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  },
  methods: {
    mergeSchemaFormWithCustom: function () {

      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
      /*schemaFormSourceDestination.form[0].tabs.splice(1, 1);
      for (const prop in this.schemaFormCustom.schemaProperties) {
        schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
      }
      schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);*/


      this.schemaForm = schemaFormSourceDestination;

    }
  }
}
</script>

<style scoped>

</style>
