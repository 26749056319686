import inherits from 'inherits'

import BaseRenderer from "diagram-js/lib/draw/BaseRenderer"

import {
  append as svgAppend,
  attr as svgAttr, classes as svgClasses,
  create as svgCreate
} from 'tiny-svg'

import { query as domQuery } from 'min-dom'

import DataTypes from "../../constants/DataTypes.json"
import DataTypeUtils from "../../utils/DataTypeUtils"
// eslint-disable-next-line no-unused-vars
import {translate} from "diagram-js/lib/util/SvgTransformUtil";
import TextUtil from "diagram-js/lib/util/Text";
import ElementUtils from "../../utils/ElementUtils";



const CONTAINER_RENDER_PRIORITY = 6000

function ContainerRenderer(eventBus, styles, canvas) {
  BaseRenderer.call(this, eventBus, CONTAINER_RENDER_PRIORITY);
}

inherits(ContainerRenderer, BaseRenderer)

ContainerRenderer.prototype.canRender = function(element) {
  console.log('Container canRender', element);
  return true; //ElementUtils.isDefaultConnector(element)
}

ContainerRenderer.prototype.drawShape = function (visuals, element) {
  console.log('Container Renderer', visuals, element);
}

ContainerRenderer.$inject = [ 'eventBus', 'styles'  ]

export default ContainerRenderer;
