import find from 'lodash/find'

import DashboardSingleLayout from './layouts/DashboardSingleLayout'
import VueDynamicRoute from './layouts/VueDynamicRoute'

const config = {
  currentApplication: null,
  applications: []
};
const vueDfApplicationService = {
  name: 'vueDfApplicationService',
  services: null,
  appHttpService: null,
  router: null,
  isComponentMode: true,
  eventBus: null,
  setCurrentApplication: function (application) {
    config.currentApplication = application;
  },
  getCurrentApplication: function () {
    return config.currentApplication;
  },
  getApplications: function () {
    return config.applications;
  },
  getCurrentApplicationHomeRoute: function () {
    return this.getCurrentApplicationRoute('/');
  },
  getCurrentApplicationRoute: function (routeLink) {
    const currentApp = this.getCurrentApplication();
    if (currentApp) {
      const route = find(currentApp.routes, function (route) {
        if (route.item) {
          return route.item.link === routeLink;
        }
        return false;
      })
      return route;
    }
  },
  getApplicationByName: function(appName){
    const app = find(config.applications, function (app) {
      return app.applicationName === appName;
    })
    if (!app) {
      return this.getCurrentApplication();
    }
    return app;
  },
  getApplicationByDashid: function (dashid) {
    const app = find(config.applications, function (app) {
      return app.dashboard.dashid === dashid;
    })
    if (!app) {
      return this.getCurrentApplication();
    }
    return app;
  },
  _createRoutes: function (conf) {
    if (!conf)
      return;

    const that = this;
    const routes = [];

    for (const prop in conf) {
     // console.log('Creating route', prop);
      const tenantSaltedName = prop;

      const route = {
        path: '',// '/' + tenantSaltedName,
        component: DashboardSingleLayout,
        redirect: '',
        meta: {requiresAuth: true},
        children: []
      };

      const def = conf[prop];
      if (def.apps) {
        for (const app in def.apps) {
          const appRootPath = app;
          const appRoutes = def.apps[app];
          route.path += ('/' + appRootPath);

          if (appRoutes) {
            appRoutes.forEach(function (routeItem) {
              const routeDef = {
                path: '/' + appRootPath + (routeItem.parent ? routeItem.parent.link : '') + routeItem.item.link, ///' + tenantSaltedName + '
                name: (routeItem.parent ? routeItem.parent.name : '') + '/' + routeItem.item.name,
                meta: {requiresAuth: true, def: routeItem},
                component: VueDynamicRoute
              };
              route.children.push(routeDef);
            });
          }
        }
      }

      routes.push(route);
    }
    return routes;
  },
  _getRoutes(app) {
    const conf = {};
    const that = this;
    const result = app.routes;
    //console.log('Create route for Tenant app', app);
    if (result) {
      result.forEach(function (route) {
        // console.log('Create route for Tenant', route);
        const saltedTenantName = app.tenantName.toLowerCase().split(' ').join('-');
        if (!conf[saltedTenantName]) {
          conf[saltedTenantName] = {
            tenantName: app.tenantName,
            apps: {}
          }
        }
        const appRootPath = route.appRootPath;
        let apps = conf[saltedTenantName].apps[appRootPath];
        if (!apps) {
          apps = [];
        }

        const existingDef = find(apps, {_id: route._id});
        if (!existingDef) {
          apps.push(route);
        }
        conf[saltedTenantName].apps[appRootPath] = apps;
      });
    }
    return conf;
  },
  loadApplication(id, next) {
    const appsRouteLoaderService = this.services[this.appHttpService];
    if (appsRouteLoaderService) {
      const that = this;
      appsRouteLoaderService.loadApplication(id, function (result) {
        if (result) {
          const conf = {};
          config.currentApplication = result;
          if(config.applications.length===0){
            config.applications.push(result);
          }
          that.eventBus.$emit('APPLICATION_LOADED', config.currentApplication);
         // console.log('Emitted APPLICATION_LOADED', config.currentApplication);
          if (next) {
            next(config.currentApplication);
          }
        } else {
          console.error("Application not loaded", config.currentApplication);
          if (next)
            next();
        }
      });
    } else {
      if (next)
        next()
    }
  },
  loadApplications(next) {
    const appsRouteLoaderService = this.services[this.appHttpService];
    if (appsRouteLoaderService) {
      const that = this;
      appsRouteLoaderService.loadApplications(null, function (result) {
        const conf = {};
        config.applications = result;
        that.eventBus.$emit('APPLICATIONS_LOADED', config.applications);
        if (next) {
          next(config.applications);
        }
      });
    } else {
      if (next)
        next();
    }
  },
  loadRoutesForApplication: function (id, next) {
    const that = this;
    this.loadApplication(id, function (application) {
      if (application) {
        const routesConf = that._getRoutes(application);
        const routes = that._createRoutes(routesConf);
        that._handleRoutes(routes);
        next(routes);
      } else {
        next(null);
      }
    });
  },
  loadAllRoutes: function (next) {
    const that = this;
    this.loadApplications(function (applications) {
      if (applications) {
        let allRoutes = [];
        applications.forEach(function (app) {
          const routesConf = that._getRoutes(app);
          const routes = that._createRoutes(routesConf);
          allRoutes = allRoutes.concat(routes);
        });

        that._handleRoutes(allRoutes);
        if (next)
          next(allRoutes);
      } else {
        if (next)
          next(null);
      }

    });
  },
  _handleRoutes: function (allRoutes) {
   // console.log('allRoutes', allRoutes);
    if (allRoutes && allRoutes.length > 0) {
      let missingItems = [];

      allRoutes.forEach(item => {
        let item2 = this.router.options.routes.find(i2 => {
          return i2.path === item.path
        });
        if (!item2) {
          missingItems.push(item);
        }
      });

      if (missingItems.length > 0) {
        this.router.addRoutes(missingItems);
        this.router.options.routes = this.router.options.routes.concat(missingItems);
      }
      this.eventBus.$emit('APPLICATIONS_ROUTES_ADDED');
    }
  },
  install(Vue, options) {

 /*   Vue.component(VueDynamicRoute.name, VueDynamicRoute);
    Vue.component(DashboardSingleLayout.name, DashboardSingleLayout);*/

    this.services = options.services;
    this.appHttpService = options.appHttpService || 'appHttpService'
    this.router = options.router || Vue.router;

   /* this.DashboardSingleLayout = options.DashboardSingleLayout || DashboardSingleLayout;
    this.VueDynamicRoute = options.VueDynamicRoute || VueDynamicRoute;*/
    this.eventBus = options.eventBus || Vue.dashboardBus;
  }
}

export default vueDfApplicationService;
