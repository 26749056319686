import FlowDesigner from "./components/FlowDesigner";


import {
  ButtonToolbarPlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  BreadcrumbPlugin,
  SidebarPlugin,
  IconsPlugin
} from 'bootstrap-vue'


const flowGenericPlugin = {
  install(Vue, options) {
    Vue.component(FlowDesigner.name, FlowDesigner);
    Vue.use(ButtonToolbarPlugin)
    Vue.use(ButtonGroupPlugin)
    Vue.use(ButtonPlugin)
    Vue.use(BreadcrumbPlugin)
    Vue.use(SidebarPlugin)
    Vue.use(IconsPlugin)


  }
}

export default flowGenericPlugin;
