var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.row.item.pages
      ? _c("div", { staticClass: "p-3" }, [
          _vm.row.item.pages
            ? _c(
                "ul",
                { staticClass: "list-group" },
                _vm._l(_vm.row.item.pages, function (page) {
                  return _c("li", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("span", [
                          _vm._v(
                            "\n\t                    " +
                              _vm._s(page.category) +
                              "\n\t                 "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-10" }, [
                        _c(
                          "a",
                          { attrs: { href: page.url, target: "_blank" } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(page.url) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-warning",
        on: { click: _vm.row.toggleDetails },
      },
      [_vm._v("Hide Pages")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }