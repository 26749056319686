<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <div class="ace-container-wrapper"  :class="[isValid ? '': 'is-invalid']">
        <div :style="fieldForm.style" class="ace-container">
          <div class="ace-config small" v-if="fieldForm.config && fieldForm.config.showToolbar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                Language:
                <label class="radio-inline m-1" v-for="(opt, index) in languages">
                  <input :value="opt" type="radio" v-model="selectedLanguage">
                  <span v-html="opt"></span>
                </label>
              </li>
              <li class="list-inline-item">
                Theme:
                <label class="radio-inline m-1" v-for="(opt, index) in themes">
                  <input :value="opt" type="radio" v-model="selectedTheme">
                  <span v-html="opt"></span>
                </label>
              </li>
            </ul>
          </div>
          <vue-ace-editor  :config="aceConfig" :content="aceValue"
                          :font-size="fontSize" :height="fieldForm.height || height" :placeholder="resolvePlaceholder(fieldForm)"
                          :position="position"
                          :readonly="fieldForm.readonly"
                          :required="fieldForm.required" :width="fieldForm.width || width"
                          class="form-control"
                           :static-word-completer="staticAceWordCompleter"
                          v-on:change-content="onAceValueChanged"></vue-ace-editor>
        </div>
        <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
      </div>

    </component-base>
  </div>
</template>

<script>
  import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
  import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'

  const VueAceEditor = ()=>import("../aceEditor/VueAceEditor");
  export default {
    name: "SfTextAreaAce",
    mixins: [componentMixin, simpleComponentMergeInMixin],
    components: { VueAceEditor },
    props:{
      staticAceWordCompleter:{ type: Object}
    },
    data() {
      return {
        aceValue: '',
        tmpValue: '',
        width: '100%',
        height: '100%',
        position: 'absolute',
        fontSize: 14,
        validationMessage: '',
        themes: ['xcode', 'monokai'],
        selectedTheme: 'xcode',
        languages: ['json', 'yaml', 'css', 'html', 'javascript'],
        selectedLanguage: 'json',
        aceConfig: {}
      }
    },
    created() {
      if (this.fieldForm.config) {
        this.selectedLanguage = this.fieldForm.config.lang || 'json';
        this.selectedTheme = this.fieldForm.config.theme || 'xcode';
        this.aceConfig = this.fieldForm.config;
      }
     // this.parseValue();
     // console.log('sf ace config: ', this.aceConfig, this.fieldForm.config, this.fieldForm);
    },
    beforeMount() {
      const value = (this.value === undefined || this.value === null)?this.fieldForm.default:this.value;
      this.parseValue(value);
    },
    watch: {
      selectedTheme: {
        handler(newVal, oldVal) {
          this.$set(this.aceConfig, 'theme', newVal);
        }
      },
      selectedLanguage: {
        handler(newVal, oldVal) {
          this.$set(this.aceConfig, 'lang', newVal);
        }
      },
      aceValue:{
        handler(newVal, oldVal){
       //   console.log('ace val cjhanged in handler', newVal);
          if (newVal!==this.localValue){
            this.localValue = newVal;
          }
        },
        deep: true
      },
      localValue: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            if (newVal) {
              /*if (typeof newVal === 'object') {
                this.selectedLanguage = 'json';
                this.aceValue = JSON.stringify(this.value, null, 2);
              } else {
                this.aceValue = this.value;
              }*/
              this.parseValue(this.value);
            } else {
              this.aceValue = null;
            }
          }
        },
        deep: true
      }
    },
    methods: {
      parseValue: function(value){
        if (value === undefined || value === null)
          this.aceValue = null;
        else if (typeof value === 'object') {
          this.selectedLanguage = 'json';
          this.aceValue = JSON.stringify(value, null, 2);
        } else {
          let tmp = value;
          if(this.selectedLanguage==='json'){
            try{
              let obj = JSON.parse(value);
              tmp = JSON.stringify(obj, null, 2);
            }catch(e){

            }
          }
          this.aceValue = tmp;
        }
      },
      onAceValueChanged: function (event) {
        let val = event;
        /*if (!this.fieldForm.config || !this.fieldForm.config.lang || this.fieldForm.lang === 'json') {
          try {
            if (event)
              val = JSON.parse(event);
          } catch (e) {
            console.warn('Not valid JSON');
          }
        }*/
        //console.log('parsing onAceValueChanged', val.this.fieldForm);


        //this.isDirty = true;
        //this.$emit('input', val);
        //this.localValue = val;
      //  console.log('ace value changed', val);
        this.aceValue = val;
      }
    }
  }
</script>

<style scoped>
  .ace-container-wrapper {
    width: 100%;
    min-height: 100%;
    display: inline-block;
    position: relative;
    /*margin-bottom: 10px;*/
  }

  .ace-container {

  }
</style>
