
import VueDfChartingView from './VueDfChartingView'
import VueDfChartingEdit from  './VueDfChartingEdit'

let widget = {
  type: 'VueDfCharting',
  title: 'Charting',
  description: 'Configure Charting widget',
  category: 'Data',
  editComponent: 'VueDfChartingEdit',
  viewComponent: 'VueDfChartingView',
  editableSettings:{
    editable: true
  },
  config: {
    chart:{
      config:{},
      data:null
    },
    data: {}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfChartingView.name, VueDfChartingView)
    Vue.component(VueDfChartingEdit.name, VueDfChartingEdit)
    Vue.widgetStore.widget(widget);
  }
}

export default vueDfPlugin
