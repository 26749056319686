import VueDfSchemaFormEdit from './VueDfSchemaFormEdit'
import VueDfSchemaFormView from './VueDfSchemaFormView'

let widget = {
  type: 'VueDfSchemaForm',
  title: 'SchemaForm',
  description: 'Configure SchemaForm widget',
  editComponent: 'VueDfSchemaFormEdit',
  viewComponent: 'VueDfSchemaFormView',
  category: 'Data',
  editableSettings:{
    editable: true
  },
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfSchemaFormView.name, VueDfSchemaFormView)
    Vue.component(VueDfSchemaFormEdit.name, VueDfSchemaFormEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
