<template>

</template>

<script>
  export default {
    name: "VueDfWidgetTemplateView"
  }
</script>

<style scoped>

</style>
