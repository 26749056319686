var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "component-base",
    { attrs: { "field-form": _vm.fieldForm } },
    [
      _c("schema-form-editor", {
        attrs: {
          "editor-class": _vm.fieldForm.editorClass || "col",
          "enable-config": _vm.fieldForm.enableConfig || false,
          "enable-elements": _vm.fieldForm.enableElements || false,
          "is-debug": _vm.fieldForm.isDebug || true,
          name: _vm.fieldForm.editorName || "formEditor",
          value: _vm.schemaFormValue,
        },
        on: { onSchemaChanged: _vm.onSchemaChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }