
import vueSchemaFormPlugin from "vue-json-schema-form";
import vueSchemaFormEditor from "vue-json-schema-form-editor";
import vueDashboardFrameworkPlugin from "vue-dashboard-framework";
import vueDashboardFrameworkComponentsPlugin from "vue-dashboard-framework-components";
import vueDashboardFrameworkPluginApp from "vue-dashboard-framework-app-components";
import vueDashboardFrameworkIntegrations from "vuedf-integrations";
import vueDiagramPlugin from "vue-diagram"

import ServiceManager from "vue-dashboard-framework/src/components/vuedf/serviceManager";
import StorageService from "vue-dashboard-framework/src/components/vuedf/storageService";
import HttpServiceManager from "vue-dashboard-framework/src/components/vuedf/httpDfCrudService";
import VueDfAppHttpService from "vue-dashboard-framework-app-components/src/components/VueDfApp/vueDfAppHttpService";
import VueDfApplicationService
  from "vue-dashboard-framework-app-components/src/components/VueDfApp/vueDfApplicationService";

import { jsulatorPlugin } from "vue-json-schema-form/src/plugins";
import { EventBus } from "vue-json-schema-form/src/plugins"
import DashboardBus from 'vue-dashboard-framework/src/components/vuedf/eventBus'

const plugin = {
  install(Vue, options) {

    const appConfig = options.appConfig || Vue.appConfig;
    const router = options.router || Vue.router;

    Vue.use(jsulatorPlugin);
    Vue.use(EventBus);
    Vue.use(DashboardBus);

    Vue.use(vueSchemaFormPlugin)
    Vue.use(vueSchemaFormEditor);

    Vue.use(vueDashboardFrameworkPlugin, {
      eventBus: appConfig.WSEventBusOptions
    });
    Vue.use(vueDashboardFrameworkComponentsPlugin);
    Vue.use(vueDashboardFrameworkPluginApp);
    Vue.use(vueDashboardFrameworkIntegrations);
    Vue.use(vueDiagramPlugin);

    Vue.use(ServiceManager, {
      services: [{
        service: StorageService,
        options: appConfig.vueDashboardFrameworkPlugin.options.storageServiceOptions || {}
      }, {
        service: HttpServiceManager,
        options: appConfig.vueDashboardFrameworkPlugin.options.httpServiceOptions
      }]
    });

    const vueDfAppHttpService = {
      service: VueDfAppHttpService,
      options: appConfig.vueDashboardFrameworkPlugin.options.vueDfAppHttpService
    }
    const vueDfApplicationService = {service: VueDfApplicationService, options: {router: router}}

    Vue.serviceManager.add(Vue, vueDfAppHttpService);
    Vue.serviceManager.add(Vue, vueDfApplicationService);
  }

}


export default plugin
