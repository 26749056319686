var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "column-data-view" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col column-data-container overflow-auto" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap" },
            _vm._l(_vm.firstColumn, function (item, index) {
              return _c("div", { staticClass: "column-item border" }, [
                _c("div", { staticClass: "card-plain card-column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-header font-weight-bold",
                      attrs: { "data-background-color": "gray" },
                    },
                    [
                      _c("div", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "ul",
                      { staticClass: "list-group" },
                      _vm._l(_vm.columns, function (column) {
                        return _c("li", { staticClass: "list-group-item" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(column[index]) +
                              "\n                  "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }