import VueDfFeedEdit from './VueDfFeedEdit'
import VueDfFeedView from './VueDfFeedView'

const widget = {
  type: 'VueDfFeed',
  title: 'Feed',
  description: 'Displays a list of Feeds results',
  category: 'Simple',
  editComponent: 'VueDfFeedEdit',
  viewComponent: 'VueDfFeedView',
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  },
  schemaFormCustom: {
    schemaProperties: {
      url: {
        type: 'string',
        title: 'URL',
        description: 'Enter RSS feed to monitor'
      },
      size: {
        type: 'integer',
        title: 'Size',
        description: 'Number of items to show'
      },
      viewType: {
        type: 'string',
        title: 'View Type',
        description: 'Select how to show content',
        enum: ['COMPACT', 'MEDIUM', 'MAGAZINE', 'FULL'],
        default: 'COMPACT'
      }
    },
    formItem: {
      title: 'Feed Definition',
      items: [
        {
          type: 'section',
          htmlClass: 'row',
          items: [
            {
              key: 'url',
              htmlClass: 'col-6',
              placeholder: 'Enter URL'
            },
            {
              key: 'size',
              htmlClass: 'col-3',
              placeholder: 'Enter number of items to show'
            },
            {
              key: 'viewType',
              htmlClass: 'col-3',
              placeholder: 'Select View Type'
            }
          ]
        }
      ]
    }
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfFeedEdit.name, VueDfFeedEdit)
    Vue.component(VueDfFeedView.name, VueDfFeedView)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
