var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor-container", attrs: { extensions: _vm.extensions } },
    [_c("editor-content", { attrs: { editor: _vm.editor } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }