import VueDfTableView from './VueDfTableView'
import VueDfTableEdit from './VueDfTableEdit'

let widget = {
  type: 'VueDfTable',
  title: 'Table',
  description: 'Displays a table of data',
  category: 'Data',
  editComponent: 'VueDfTableEdit',
  viewComponent: 'VueDfTableView',
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfTableView.name, VueDfTableView)
    Vue.component(VueDfTableEdit.name, VueDfTableEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
