var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "div",
          {
            staticClass: "editor-container-wrapper",
            class: [_vm.isValid ? "" : "is-invalid"],
          },
          [_c("tiptap-editor")],
          1
        ),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }