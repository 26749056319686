<template>
  <div>
    <component :is="componentType" :value="dataModel"></component>
  </div>
</template>

<script>
  import widgetComponentViewMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins";
  import previewMixin from "./previewMixin";

  export default {
    name: "VueDfDataPreviewView",
    mixins: [widgetComponentViewMixins, previewMixin],
    data() {
      return {
        componentType: '',
        show: false
      }
    },
    watch: {
      dataModel: {
        handler(newVal, oldVal) {
          this.resolveComponentType();
        },
        deep: true
      }
    },
    methods: {
      resolveComponentType: function () {
        if (this.dataModel) {
          let type = 'HierarchicalTypeView';
          if (Array.isArray(this.dataModel)) {
            const firstItem = this.dataModel[0];
            if (firstItem) {
              if (Array.isArray(firstItem)) {
                type = 'ColumnTypeView';
              } else {
                type = 'RowTypeView'
              }
            }
          } else if (Object.values(this.dataModel).length) {

          }
          this.componentType = type;
        }
      }
    }
  }
</script>

<style scoped>

</style>
