import { render, staticRenderFns } from "./VueDfFeedView.vue?vue&type=template&id=d89758cc&scoped=true&"
import script from "./VueDfFeedView.vue?vue&type=script&lang=js&"
export * from "./VueDfFeedView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89758cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d89758cc')) {
      api.createRecord('d89758cc', component.options)
    } else {
      api.reload('d89758cc', component.options)
    }
    module.hot.accept("./VueDfFeedView.vue?vue&type=template&id=d89758cc&scoped=true&", function () {
      api.rerender('d89758cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-components/src/components/vueDfComponents/VueDfFeed/VueDfFeedView.vue"
export default component.exports