<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <div class="editor-container-wrapper"  :class="[isValid ? '': 'is-invalid']">
        <tiptap-editor></tiptap-editor>
      </div>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    </component-base>
  </div>
</template>

<script>
  import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
  import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'
  import TiptapEditor from "../tiptapEditor/TiptapEditor";

  export default {
    name: "SfTextHtmlEditor",
    components:{TiptapEditor},
    mixins: [componentMixin, simpleComponentMergeInMixin]
  }
</script>

<style scoped>
  .editor-container-wrapper {
    width: 100%;
    height: 100%;
    border: 1px;
  }

</style>
