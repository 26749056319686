var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button-toolbar",
        { attrs: { "key-nav": "", justify: "" } },
        [
          _c(
            "b-button-group",
            { attrs: { size: "sm" } },
            [
              _c(
                "b-breadcrumb",
                { staticStyle: { background: "transparent", height: "16px" } },
                [
                  _vm.flow !== null
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          Flow " +
                            _vm._s(_vm.flow.description || _vm.flow.id) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.selection !== null &&
                  _vm.selection.businessObject.type === "property"
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selection.parent.businessObject.type) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.selection !== null &&
                  _vm.selection.businessObject.type === "property"
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selection.parent.businessObject.description ||
                                _vm.selection.id
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.selection !== null
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selection.businessObject.type) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.selection !== null &&
                  _vm.selection.businessObject.type === "property"
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selection.businessObject.name ||
                                _vm.selection.id
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.selection !== null &&
                  _vm.selection.businessObject.type !== "property"
                    ? _c("b-breadcrumb-item", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selection.businessObject.description ||
                                _vm.selection.id
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button-group",
            { staticClass: "float-right", attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.exportFlow },
                },
                [_vm._v("\n        Export\n      ")]
              ),
              _c(
                "b-button",
                { on: { click: _vm.toggleSidebar } },
                [_vm._v("\n        Entities\n        "), _c("b-icon-list")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flow-editor-container" }, [
        _c(
          "div",
          { staticClass: "flow-panel" },
          [
            _vm.diagram
              ? _c("flow-editor-panel", {
                  staticStyle: { float: "right" },
                  attrs: { flow: _vm.flow, modeler: _vm.diagram },
                  on: { ontoggle: _vm.ontogglePanel },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", {
          ref: "container",
          class: ["flow-canvas", { active: !_vm.panelState }],
        }),
      ]),
      _c("flow-editor-entities-sidebar", {
        attrs: { "show-sidebar": _vm.showSidebar, flow: _vm.flow },
        on: {
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }