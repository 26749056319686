import { render, staticRenderFns } from "./VueDfDataPreviewView.vue?vue&type=template&id=01e6de68&scoped=true&"
import script from "./VueDfDataPreviewView.vue?vue&type=script&lang=js&"
export * from "./VueDfDataPreviewView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01e6de68",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01e6de68')) {
      api.createRecord('01e6de68', component.options)
    } else {
      api.reload('01e6de68', component.options)
    }
    module.hot.accept("./VueDfDataPreviewView.vue?vue&type=template&id=01e6de68&scoped=true&", function () {
      api.rerender('01e6de68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-components/src/components/vueDfComponents/VueDfDataPreview/VueDfDataPreviewView.vue"
export default component.exports