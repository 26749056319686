var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3" }, [
        _vm._v("\n      " + _vm._s(_vm.ruleName) + "\n    "),
      ]),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-9 col-md-9" },
        [
          _c(_vm.ruleCtrl.ruleComponent, {
            tag: "component",
            attrs: { value: _vm.ruleCtrl.ruleData },
            on: { input: _vm.ruleCtrl.updateRuleData },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }