//import draggable from "vuedraggable"

/*import VueAceEditor from "./aceEditor"
import VueAceMarkdownEditor from "./aceMarkdown";
*/
/*import EditorJs from "./editorJs";*/
import SchemaFormEditor from "./components/schemaFormEditor";


import SfTextMarkdown from "./components/SfTextMarkdown";
import SfTextAreaAce from "./components/SfTextAreaAce";
import SfVueSchemaFormEditor from "./components/schemaFormEditor/SfVueSchemaFormEditor";
import SfTextHtmlEditor from './components/SfTextHtmlEditor'
import SfQueryBuilder from "./vueQueryBuilder/SfQueryBuilder";
import SfTableEditor from "./tableEditor/SfTableEditor";
//import SfDiagramEditor from "./diagramEditor/SfDiagramEditor";
import SfBpmnEditor from "./bpmnEditor/SfBpmnEditor";

const vueSchemaFormEditor = {
  registerComponents(Vue, options) {
    Vue.component(SfTextMarkdown.name, SfTextMarkdown)
    Vue.component(SfTextAreaAce.name, SfTextAreaAce)
    Vue.component(SfVueSchemaFormEditor.name, SfVueSchemaFormEditor)
    Vue.component(SfTextHtmlEditor.name, SfTextHtmlEditor)
    Vue.component(SfQueryBuilder.name, SfQueryBuilder)
    Vue.component(SfTableEditor.name, SfTableEditor)
  //  Vue.component(SfDiagramEditor.name,SfDiagramEditor)
    Vue.component(SfBpmnEditor.name, SfBpmnEditor)

    Vue.defaultMapper.fieldMapper.markdown = SfTextMarkdown;
    Vue.defaultMapper.formatMapper.markdown = SfTextMarkdown;
    Vue.defaultMapper.fieldMapper.ace = SfTextAreaAce;
    Vue.defaultMapper.formatMapper.ace = SfTextAreaAce;
    Vue.defaultMapper.fieldMapper.html = SfTextHtmlEditor;
    Vue.defaultMapper.formatMapper.html = SfTextHtmlEditor;
    Vue.defaultMapper.fieldMapper.schemaFormEditor = SfVueSchemaFormEditor;

    Vue.defaultMapper.fieldMapper.queryBuilder = SfQueryBuilder;
    Vue.defaultMapper.fieldMapper.tableEditor = SfTableEditor;
    //Vue.defaultMapper.fieldMapper.diagram = SfDiagramEditor;
    Vue.defaultMapper.fieldMapper.bpmn = SfBpmnEditor;


  },
  install(Vue, options) {

    if (!Vue.__schemaFormEditorRegistered) {
     // Vue.use(schemaFormPlugin);
      Vue.use(SchemaFormEditor)

      this.registerComponents(Vue, options);
      Vue.__schemaFormEditorRegistered = true;
    }
  }
};
export default vueSchemaFormEditor;
