import bpmn from './bpmn'
import flowGenericPlugin from "vue-diagram/src/flow-generic";

const plugin = {
  install(Vue, options) {
    Vue.use(bpmn);
    Vue.use(flowGenericPlugin);
  }
}

export default plugin;
