import RowTypeView from "./components/RowTypeView";
import ColumnTypeView from "./components/ColumnTypeView";
import HierarchicalTypeView from "./components/HierarchicalTypeView";

const previewMixin ={
  components: {RowTypeView, ColumnTypeView, HierarchicalTypeView},
  data(){
    return {

    }
  },
  methods:{

  }
}

export default previewMixin
