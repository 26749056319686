<template>
  <component-base v-bind:field-form="fieldForm">
    <schema-form-editor :editor-class="fieldForm.editorClass || 'col'"
                        :enable-config="fieldForm.enableConfig || false"
                        :enable-elements="fieldForm.enableElements || false"
                        :is-debug="fieldForm.isDebug || true"
                        :name="fieldForm.editorName || 'formEditor'"
                        :value="schemaFormValue"
                        @onSchemaChanged="onSchemaChanged">
    </schema-form-editor>
  </component-base>
</template>

<script>
  const SchemaFormEditor = () => import('./SchemaFormEditor');
  import buildMixins from 'vue-json-schema-form/src/components/schemaForm/buildMixins'
  import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
  import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'

  export default {
    name: "SfVueSchemaFormEditor",
    components: {SchemaFormEditor},
    mixins: [buildMixins, componentMixin, simpleComponentMergeInMixin],
    data() {
      return {
        schemaFormValue: {
          schema: {},
          form: []
        }
      }
    },
    watch: {
      value: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.resolveSchemaForm();
          }
        },
        deep: true
      }
    },
    created() {
      this.resolveSchemaForm();
    },
    methods: {
      resolveSchemaForm: function () {
        const schemaForm = this.resolveValue(this.value, this.fieldForm);
        this.schemaFormValue.schema = schemaForm ? schemaForm.schema : {};
        this.schemaFormValue.form = schemaForm ? schemaForm.form : [];
      },
      onSchemaChanged: function (schemaForm) {
        this.schemaFormValue.schema = schemaForm.schema;
        this.schemaFormValue.form = schemaForm.form;
        this.$emit('input', schemaForm);
      }
    }
  }
</script>

<style scoped>

</style>
