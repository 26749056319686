<template>
  <div class="bpmn-container" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <div class="bpmn-container-holder" v-if="visible">
      <div v-if="isEditor==='EDITOR'" class="bpmn-editor-container">
        <bpmn-editor :style="{height: this.model.config.data.height }" v-if="bpmnValue"
                     :value="bpmnValue"></bpmn-editor>
      </div>
      <div v-if="isEditor==='VIEWER'" class="bpmn-viewer-container">
        <bpmn-viewer :style="{height: this.model.config.data.height }" v-if="bpmnValue"
                     :xml-data="bpmnValue"></bpmn-viewer>
      </div>
    </div>
  </div>
</template>

<script>
import widgetComponentViewMixins from "vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins";

export default {
  name: "VueDfBpmnDiagramView",
  mixins: [widgetComponentViewMixins],
  data() {
    return {
      bpmnValue: null,
      visible: true
    }
  },
  watch: {
    'dataModel.bpmn': {
      handler(newVal, oldVal) {
        this.bpmnValue = newVal;
        // console.log('Bpmn val changed', this.bpmnValue);
        const that = this;
        this.visible = false;
        this.$nextTick(function () {
          that.visible = true;
        });
      }
    }
  },
  mounted() {
    const val = this.dataModel && this.dataModel.bpmn ? this.dataModel.bpmn : this.defaultBpmn
    this.bpmnValue = val;//this.defaultBpmn
  },
  computed: {
    /*bpmnValue(){
      const val = this.dataModel && this.dataModel.bpmn?this.dataModel.bpmn : this.defaultBpmn;
      console.log('val', val);
      return val;
    },*/
    isEditor() {
      return this.$jsulator.evaluate('$config.data.editorOrViewer$', this.model);
    },
    defaultBpmn() {
      const def = this.$jsulator.evaluate('$config.data.defaultBpmn$', this.model);
      //console.log('Def bpmn', def);
      return def;
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
