var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    {
      staticClass: "m-3",
      attrs: { "content-class": "mt-3" },
      scopedSlots: _vm._u([
        {
          key: "tabs-end",
          fn: function () {
            return [
              _c(
                "b-nav-item",
                { on: { click: _vm.createFlow } },
                [_c("b-icon-plus")],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.currentTab,
        callback: function ($$v) {
          _vm.currentTab = $$v
        },
        expression: "currentTab",
      },
    },
    [
      _c(
        "b-tab",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v("\n        Flows\n      ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("flow-selector", {
            attrs: { "flow-manager": _vm.flowManager },
            on: { openFlow: _vm.openFlow, closeFlow: _vm.closeFlow },
          }),
        ],
        1
      ),
      _c(
        "b-tab",
        { attrs: { title: "Type System" } },
        [_c("type-system-editor")],
        1
      ),
      _vm._l(_vm.openedFlowIds, function (flowId, index) {
        return _c(
          "b-tab",
          {
            key: index,
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getTitle(flowId)) +
                          "\n        "
                      ),
                      _c("b-button", {
                        attrs: { variant: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.closeFlow(flowId)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          [
            _c("flow-editor", {
              attrs: { "flow-manager": _vm.flowManager, flowId: flowId },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }