<template>
  <div :class="{active:detailsConf.isOpen}" class="properties-panel">
    <span @click="specificationToggle" class="specification-toggle-button" title="Show Details">
      <i class="fas fa-cog"></i>
    </span>
    <div v-if="detailsConf.isOpen" class="specification-wrapper">
      It is open 1
      <!--      <component :is="getComponent" v-if="element" :element="element" :modeler="modeler"></component>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowEditorPanel",
  props: {
    flow: {
      type: Object,
      required: true
    },
    modeler: {
      type: Object,
      required: true
    },
    options: {
      type: Object
    }
  },
  data() {
    return {
      eventBus: null,
      element: null,
      detailsConf: {
        isOpen: false
      }
    }
  },
  mounted() {
    this.handleModeler();
  },
  beforeDestroy(){
    this.eventBus.off('root.added', this.rootAdded)
   // this.eventBus.off('element.click', this.elementClick)
    this.eventBus.off('selection.changed', this.selectionChange);
    this.eventBus.on('root.set', this.rootSet)
  },
  methods: {
    specificationToggle() {
      this.detailsConf.isOpen = !this.detailsConf.isOpen;

      console.log('in panel specificationToggle', this.element?this.element.id:null, this.modeler, this.detailsConf.isOpen);
      this.$emit('ontoggle', this.detailsConf.isOpen);
    },
    rootSet(e){
      console.log('on set', e)

    },
    rootAdded(e) {

      console.log('on add', e)
      if (e.element.type === 'bpmn:Process') {
        this.element = null
        this.$nextTick().then(() => {
          this.element = e.element
        })

      }
    },
    /*elementClick(e){
      const {element} = e
      console.log('on click', element)
      if (element.type === 'bpmn:Process') {
        this.element = element
      }
    },*/
    selectionChange(e){
      this.element = null
      const element = e.newSelection[0]
      if (element) {
      console.log('on selection changed', element, element.id, this.flow);

        /*this.$nextTick().then(() => {
          this.element = element
        })*/
        this.element = element
      }
    },
    handleModeler() {
      console.log('models', this.modeler, this.flow);
      if (this.modeler && this.modeler.get('eventBus') && this.eventBus === null) {
        this.eventBus = this.modeler.get('eventBus');
      }
      this.eventBus.on('root.added', this.rootAdded)
      this.eventBus.on('root.set', this.rootSet)
     // this.eventBus.on('element.click', this.elementClick)
      this.eventBus.on('selection.changed', this.selectionChange);

    }
  }
}
</script>

<style scoped>

.specification-wrapper-active {
  /* width: 360px;*/
}

.specification-toggle-button {
  float: right;
}

.specification-wrapper {

}

.properties-panel.active {
  width: 400px;
}

.properties-panel {
  background-color: #f8f8f8;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 40px;
  z-index: 10;
  padding: 10px;
  border-left: 1px solid #ccc;
  overflow: auto;
}

</style>
