<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <div :class="[isValid ? '': 'is-invalid']">
        <vue-ace-markdown-editor :class="[isValid ? '': 'is-invalid']"
                                 :placeholder="resolvePlaceholder(fieldForm)" :readonly="fieldForm.readonly"
                                 :required="fieldForm.required"
                                 class=""
                                 v-model="localData">

        </vue-ace-markdown-editor>
      </div>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage }}</small>
    </component-base>
  </div>
</template>

<script>
import componentMixin from 'vue-json-schema-form/src/components/schemaForm/componentMixins'
import simpleComponentMergeInMixin from 'vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin'

const VueAceMarkdownEditor = () => import("../aceMarkdown/VueAceMarkdownEditor");

export default {
  name: "SfTextMarkdown",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  components: {VueAceMarkdownEditor},
  data() {
    return {
      localData: undefined
    }
  },
  mounted() {
    if (this.localValue) {
      this.localData = this.localValue;
    } else if (this.fieldForm.schema && this.fieldForm.schema.default) {
      this.localData = this.fieldForm.schema.default;
    }
  },
  watch: {
    localData: function (newVal, oldVal) {
      this.localValue = newVal;
    }
  }
}
</script>

<style scoped>

</style>
