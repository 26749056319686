<template>
  <schema-form ref="schemaForm" :form="resolveForm()" :schema="resolveSchema()" @validationResult="onValidationResult"
               v-model="dataModel"></schema-form>
</template>

<script>
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

  export default {
    name: "VueDfDataSourceView",
    mixins: [widgetComponentViewMixins],
    data() {
      return {

      }
    },
    methods:{
      resolveSchema: function () {
        return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.schema : {};
      },
      resolveForm: function () {
        return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.form : [];
      },
      onValidationResult: function (value) {
        this.validationResult = value;

        if (value.valid) {
          this.onSubmit(this.dataModel);
        } else {
          this.notifyVueMessage('Datasource Filter form is not valid', 'bottom', 'right', 'danger', 10000);
          console.log('Not valid form', this.validationResult);
        }
      },
    }
  }
</script>

<style scoped>

</style>
