<template>
  <div class="json-container"><draggable-json-tree :is-draggable=false :data="value" :level=3></draggable-json-tree></div>
</template>

<script>
  import typeViewMixin from "./typeViewMixin";
  import DraggableJsonTree from "vue-json-schema-form/src/components/draggableJsonTree/DraggableJsonTree";

  export default {
    name: "HierarchicalTypeView",
    components: {DraggableJsonTree},
    mixins: [typeViewMixin]
  }
</script>

<style scoped>
  .json-container ::v-deep .json-tree-root {
    min-width: 10px;

  }
</style>
