<template>
  <div>
    <div class="table-container">
      <!--      <v-runtime-template ref="templateTable" :template="template"></v-runtime-template>-->
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" v-for="field in fields">{{ field.label }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(rowItem, index) in data" :key="index">
          <th scope="row" @click="onDataRowClick(index, rowItem)">
            <span style="font-size: 0.6em">
            <i
              :class="['fas', { 'fa-plus': index!==currentOpenRowIndex}, {'fa-minus': index===currentOpenRowIndex}]"></i>
            </span>
          </th>
          <td v-for="(value, prop) in rowItem">
            {{ value }}
          </td>
        </tr>
        <transition mode="out-in"
                    name="fade">
          <tr v-if="currentOpenRowIndex>-1">
            <td colspan="4">
              <div class="row-data-item-details">
                <builder-component :field-mapper="fieldMapper"
                                   :form="fieldForm.items" :full-model="fullModel" :is-droppable="isDroppable"
                                   :merged-form="fieldFormArray[currentOpenRowIndex]"
                                   :parent-form="fieldForm"
                                   :schema-form-service="schemaFormService"
                                   :schema="fieldSchema.schema.items"
                                   :value="resolveRowValue(currentOpenRowItem)">
                </builder-component>
              </div>
            </td>
          </tr>
        </transition>

        </tbody>
      </table>
      <div class="btn-group">
        <button class="btn btn-sm" @click.prevent="appendToArray(resolveMaxItems(fieldForm))">New</button>
      </div>
    </div>
  </div>

</template>

<script>
import componentMixin from "vue-json-schema-form/src/components/schemaForm/componentMixins";
import simpleComponentMergeInMixin from "vue-json-schema-form/src/components/schemaForm/simpleComponentMerginMixin";
import VRuntimeTemplate from "v-runtime-template";
import {BTable, BPagination} from 'bootstrap-vue'
import tableUtils from './tableUtils'
import ObjectPath from 'vue-json-schema-form/src/utils/ObjectPath'
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import BTableRowTemplate from "./components/BTableRowTemplate";
import utils from "vue-json-schema-form/src/utils/utils";
import vueUtils from "vue-json-schema-form/src/utils/vueUtils";
import BuilderComponent from "vue-json-schema-form/src/components/schemaForm/BuilderComponent";
import merge from "lodash/merge";
import forEach from "lodash/forEach";
import TableHead from "vue-json-schema-form-editor/src/tableEditor/components/TableHeader";
import TableBody from "vue-json-schema-form-editor/src/tableEditor/components/TableBody";

export default {
  name: "SfTableEditor",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  components: {
    TableBody,
    TableHead,
    VRuntimeTemplate, BTable, BPagination, BTableRowTemplate, BuilderComponent
  },
  data() {
    return {
      currentOpenRowIndex: -1,
      currentOpenRowItem: null,
      arrayItem: null,
      arrayValues: [],
      selectable: false,
      fieldFormArray: undefined,
      selectMode: 'single',
      template: undefined,
      pagination: {
        perPage: 10,
        currentPage: 1,
        totalRows: this.totalRows
      }
    }
  },
  created() {
    this.createTableTemplate();
    this.assignArrayValues();
  },
  computed: {
    fields() {
      return this.resolveFields()
    },
    totalRows: function () {
      return this.arrayValues.length; //this.data.length;
    },
    data: function () {
      // console.log('dataModel', this.dataModel);
      /*if (this.dataModel && Array.isArray(this.dataModel)) {
        this.pagination.totalRows = this.dataModel.length;
        return this.dataModel;
      }*/
      return this.arrayValues;
    },
    tableDef: function () {
      return this.fieldForm.tableDef || {}; //this.model.config.data.tableDefinition || {};
    }
  },
  methods: {
    onDataRowClick: function (index, rowDataItem) {
      this.currentOpenRowIndex = this.currentOpenRowIndex === index ? -1 : index;
      this.currentOpenRowItem = this.currentOpenRowIndex === -1 ? null : rowDataItem;
      console.log('clicked at: ', index, rowDataItem);
    },
    resolveRowValue: function (item) {
      console.log('item ', item);
      let newItem = utils.clone(item);
      delete newItem['_showDetails'];
      console.log('newItem', newItem);
      return newItem;
    },
    resolveMaxItems: function (fieldForm) {
      let maxItems = 1000000;
      if (fieldForm.schema && fieldForm.schema.maxItems) {
        maxItems = fieldForm.schema.maxItems;
      } else if (fieldForm.maxItems) {
        maxItems = fieldForm.maxItems
      }
      return maxItems;
    },
    resolveArrayItem: function () {
      this.arrayItem = {};
      return this.arrayItem;
    },
    appendToArray: function (maxItems) {
      if (!this.arrayValues) {
        this.arrayValues = [];
      }
      if (maxItems && maxItems <= this.arrayValues.length || this.isDroppable) {
        return;
      }
      this.arrayValues.push(this.resolveArrayItem());
      this.currentOpenRowIndex = this.arrayValues.length - 1;
      this.currentOpenRowItem = this.arrayValues[this.arrayValues.length - 1];
      this.updateFormItems();
    },
    assignArrayValues: function () {
      let key = utils.clone(this.fieldForm.key);
      let vals = utils.getValueFromModel(this.fullModel, key);
      if (!vals) {
        vals = Object.assign([]);
        if (this.isDroppable) {
          vals.push(this.resolveArrayItem());
        }
        vueUtils.vueSet(this.fullModel, key, vals);
      }
      this.arrayValues = vals;

    },
    prepareTableSchema: function () {

      const fields = this.resolveFields();// use eachField cellFormat property to define styling

      const tableSchema = {
        tableDefinition: this.fieldForm.tableDef || {},
        pagination: this.pagination,
        fields: fields
      }

      tableSchema.tableDefinition.rowDetails = '<template #row-details="row"><b-table-row-template :row=row></b-table-row-template></template>';

      return tableSchema;
    },
    createTableTemplate: function () {
      //  console.log('widget id: '+this.id);
      const tableSchema = this.prepareTableSchema();
      const tableId = ObjectPath.dotted(this.fieldForm.key);
      let template = '<div class="overflow-auto"><b-table id="table_comments">' +
        '</b-table></div>';

      template = tableUtils.buildMarkup(tableSchema, tableId);
      console.log('Table template: ' + template);

      this.template = template; //tableUtils.buildMarkup(tableSchema, tableId);

    },
    onRowCommand(item, command) {
      // console.log('onRowCommand', item, command);
      if (item && command) {
        /* const tableEventDef = find(this.model.config.data.tableEvents, {eventType: 'OnRowCommand'});
         if (tableEventDef && tableEventDef.eventPublish.events) {
           this.executePublishEvents(tableEventDef.eventPublish, {item: cloneDeep(item), command: command});
         }*/
      }
    },
    onRowSelected(items) {
      //this.selected = items;
      //console.log('onRowSelected', this.model, items);
      /*if (items && items.length) {
        const tableEventDef = find(this.model.config.data.tableEvents, {eventType: 'OnRowSelected'});
        // console.log('OnRowSelected events to publish', tableEventDef);
        if (tableEventDef && tableEventDef.eventPublish.events) {
          this.executePublishEvents(tableEventDef.eventPublish, cloneDeep(items[0]));
        }
      }*/
    },
    resolveFields: function () {
      const fields = [];
      const schemaItems = this.$jsulator.evaluate('schema.items.properties', this.fieldForm);
      if (schemaItems) {
        for (let prop in schemaItems) {
          const field = {
            key: prop,
            label: schemaItems[prop].title
          }
          fields.push(field);
        }
      }
      return fields;
    },
    updateFormItems: function () {
      if (!this.arrayValues)
        return;

      const fieldArray = [];
      // const tabTitles = [];
      const that = this;
      if (this.arrayValues.length > 0) {
        for (let i = 0; i < this.arrayValues.length; i += 1) {
          //const item = this.arrayValues[i];
          const clonedItems = merge([], utils.clone(this.fieldForm.items));
          const newFieldFormItems = clonedItems.map((eachForm, index) => {
            // console.log('TabArray Processing clonedItems', eachForm, that.fieldForm);
            if (eachForm.key && that.fieldForm.key) {
              if (eachForm.type === 'fieldset') {
                const fieldsetForm = that.resolveSingleComponentCopyWithIndex(i, eachForm, that.fieldForm);
                if (fieldsetForm.items) {
                  const fieldsetItems = that.updateArrayIndexRecursively(i, fieldsetForm, that.fieldForm);
                  that.$set(fieldsetForm, 'items', fieldsetItems);
                  that.$set(fieldsetForm, 'arrayIndex', i);
                }
                return fieldsetForm;
              } else {
                const tmpEachForm = that.resolveSingleComponentCopyWithIndex(i, eachForm, that.fieldForm);
                return tmpEachForm;
              }
            } else if (eachForm.type === 'section') {
              const sectionItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              that.$set(eachForm, 'items', sectionItems);
              that.$set(eachForm, 'arrayIndex', i);
              return eachForm;
            } else if (eachForm.type === 'tabs') {
              const tabsItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              forEach(tabsItems, function (item) {
                delete item.key;
              });
              that.$set(eachForm, 'tabs', tabsItems);
              that.$set(eachForm, 'arrayIndex', i);
              return eachForm;
            } else {
              return utils.clone(eachForm);
            }

          });
          // console.log('Push to FieldArray New Field Form Items for index: '+i, newFieldFormItems, clonedItems, this.fieldForm.items);
          if (newFieldFormItems && newFieldFormItems.length > 0)
            fieldArray.push(newFieldFormItems);
        }
      }
      if (this.fieldFormArray !== fieldArray) {
        this.fieldFormArray = fieldArray;
      }
      // this.tabTitles = tabTitles;
      //   console.log('Updated tab array items', this.arrayValues, this.tabTitles, this.fieldFormArray);
    },
    updateArrayIndexRecursively: function (index, eachForm, fieldForm) {
      const that = this;
      let items = eachForm.items || [];
      if (eachForm.type === 'tabs') {
        items = eachForm.tabs;
      }
      const clonedItems = utils.clone(items);
      let tmpItems = [];
      forEach(clonedItems, function (item) {
        if (item.key) {
          item = that.resolveSingleComponentCopyWithIndex(index, item, fieldForm);
        }
        if (item.type !== 'tabarray' || item.type !== 'array') {

          if (item.type === 'section' || item.type === 'tabs') {
            item.arrayIndex = index;
          }
          if (item.items && item.items.length > 0) {
            const updatedItems = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.items = utils.clone(updatedItems);
          } else if (item.tabs && item.tabs.length > 0) {
            const updatedTabs = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.tabs = utils.clone(updatedTabs);
          }
        }
        tmpItems.push(item);
      });
      return tmpItems;
    },
    resolveSingleComponentCopyWithIndex: function (index, eachForm, fieldForm) {
      let slice = Array.isArray(eachForm.key) ? eachForm.key.slice(fieldForm.key.length) : eachForm.key;
      let eachFormCopy = utils.clone(eachForm);
      eachFormCopy.key = slice;
      const copy = this.copyWithIndex(eachFormCopy, index);
      copy.key = fieldForm.key.concat(copy.key);
      return copy;
    },
    copyWithIndex: function (field, index) {
      const that = this;
      const copy = utils.clone(field);
      copy.arrayIndex = index;
      utils.traverseForm(copy, function (form) {
        //console.log('traversing form: ' + form.type + ' = ' + form.schemaFieldKey + ', index: ' + index, form);
        that.setIndex(form, index);
      });
      return copy;
    },
    setIndex: function (field, index) {
      field.arrayIndex = index;

      if (field.key) {
        // todo fix mutable object
        // eslint-disable-next-line no-param-reassign
        let tmpKey = field.key;
        if (!Array.isArray(field.key)) {
          tmpKey = ObjectPath.parse(tmpKey);
        }
        let i = tmpKey.indexOf("");
        if (i > -1) {
          tmpKey[i] = index;
        }
        //   console.log('Old => New Key: ' + utils.clone(field.key) + ' => ' + utils.clone(tmpKey));
        field.key = tmpKey;
      }

    }

  }
}
</script>

<style scoped>
.show_details_row {
  cursor: pointer;
}
.row-data-item-details {
  padding: 5px;
}
</style>
