var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "div",
          {
            staticClass: "ace-container-wrapper",
            class: [_vm.isValid ? "" : "is-invalid"],
          },
          [
            _c(
              "div",
              { staticClass: "ace-container", style: _vm.fieldForm.style },
              [
                _vm.fieldForm.config && _vm.fieldForm.config.showToolbar
                  ? _c("div", { staticClass: "ace-config small" }, [
                      _c("ul", { staticClass: "list-inline mb-0" }, [
                        _c(
                          "li",
                          { staticClass: "list-inline-item" },
                          [
                            _vm._v("\n              Language:\n              "),
                            _vm._l(_vm.languages, function (opt, index) {
                              return _c(
                                "label",
                                { staticClass: "radio-inline m-1" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedLanguage,
                                        expression: "selectedLanguage",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: opt,
                                      checked: _vm._q(
                                        _vm.selectedLanguage,
                                        opt
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedLanguage = opt
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(opt) },
                                  }),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "li",
                          { staticClass: "list-inline-item" },
                          [
                            _vm._v("\n              Theme:\n              "),
                            _vm._l(_vm.themes, function (opt, index) {
                              return _c(
                                "label",
                                { staticClass: "radio-inline m-1" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedTheme,
                                        expression: "selectedTheme",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: opt,
                                      checked: _vm._q(_vm.selectedTheme, opt),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedTheme = opt
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(opt) },
                                  }),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("vue-ace-editor", {
                  staticClass: "form-control",
                  attrs: {
                    config: _vm.aceConfig,
                    content: _vm.aceValue,
                    "font-size": _vm.fontSize,
                    height: _vm.fieldForm.height || _vm.height,
                    placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
                    position: _vm.position,
                    readonly: _vm.fieldForm.readonly,
                    required: _vm.fieldForm.required,
                    width: _vm.fieldForm.width || _vm.width,
                    "static-word-completer": _vm.staticAceWordCompleter,
                  },
                  on: { "change-content": _vm.onAceValueChanged },
                }),
              ],
              1
            ),
            !_vm.isValid
              ? _c("small", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }