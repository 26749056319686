<template>
  <div class="editor-container" :extensions="extensions">
    <editor-content :editor="editor"/>
  </div>
</template>

<script>
  // check this for full menu and other: https://tiptap.dev/
  import {Editor, EditorContent, EditorMenuBar} from 'tiptap';
  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

  export default {
    name: "TiptapEditor",
    components: {
      EditorMenuBar,
      EditorContent,
    },
    data() {
      return {
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        editor: new Editor({
          content: `
          <h1>Yay Headlines!</h1>
          <p>All these <strong>cool tags</strong> are working now.</p>
        `,
        })
      }
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    mounted() {
    }
  }
</script>

<style scoped>
  .editor-container {
    border: 1px;
  }
</style>
