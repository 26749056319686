import VueDfBpmnProcess from "vue-diagram/src/bpmn/widgets/VueDfBpmnProcess";
import VueDfBpmnDiagram from "vue-diagram/src/bpmn/widgets/VueDfBpmnDiagram";

const vueDfPlugin = {
  install(Vue, options) {
    Vue.use(VueDfBpmnProcess);
    Vue.use(VueDfBpmnDiagram);
  }
}
export default vueDfPlugin
