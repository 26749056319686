var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { staticClass: "widget-markdown" },
        [_c("vue-markdown-viewer", { attrs: { model: _vm.content } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }