import BpmnEditor from "./BpmnEditor";
import BpmnViewer from "./BpmnViewer";
import widgets from './widgets'

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(BpmnEditor.name, BpmnEditor);
    Vue.component(BpmnViewer.name, BpmnViewer);

    Vue.use(widgets);
  }
}
export default vueDfPlugin
