<template>
  <div>
    <div class="column-data-view">
      <div class="row">
        <div class="col column-data-container overflow-auto">
          <div class="d-flex flex-row flex-nowrap">
            <div class="column-item border" v-for="(item, index) in firstColumn">
              <div class="card-plain card-column">
                <div data-background-color="gray" class="card-header font-weight-bold">
                  <div class="card-title">{{item}}</div>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item" v-for="column in columns">
                      {{column[index]}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import typeViewMixin from "./typeViewMixin";

  export default {
    name: "ColumnTypeView",
    mixins: [typeViewMixin],
    computed: {
      colSize: function () {

      },
      firstColumn: function () {
        return this.value && this.value.length ? this.value[0] : [];
      },
      columns: function () {
        return this.value && this.value.length ? this.value.slice(1) : [];
      }
    }
  }
</script>

<style scoped>

  .column-item {
    min-width: 200px;
    margin-right: 20px;
  }

  .column-data-container{
    min-height: 450px;
    background-color: #f5f5f5;
    padding: 10px;
  }

  .card-plain .card-header {
    background-color: #c3c3c3;
    padding: 5px 0px 5px 20px;
  }

  .card-plain .card-body {
    padding: 5px;
  }

</style>
