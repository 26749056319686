import VueDfDataSourceView from './VueDfDataSourceView'
import VueDfDataSourceEdit from './VueDfDataSourceEdit'

let widget = {
  type: 'VueDfDataSource',
  title: 'Data Source',
  description: 'Configure Data Source widget',
  editComponent: 'VueDfDataSourceEdit',
  viewComponent: 'VueDfDataSourceView',
  category: 'Data',
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfDataSourceView.name, VueDfDataSourceView)
    Vue.component(VueDfDataSourceEdit.name, VueDfDataSourceEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
