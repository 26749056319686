//
import { schemaFormPlugin } from './plugins' //'./components/schemaForm'

import {schemaFormHelperPlugins} from './plugins'
//import './assets/sass/vue-schema-form.scss'
import { jsulatorPlugin } from './plugins' //"./utils/jsulatorPlugin";

import { EventBus } from  './plugins' //'./eventBus'

const vueSchemaFormPlugin = {
  install(Vue, options) {

    Vue.use(jsulatorPlugin);
    Vue.use(EventBus)
    Vue.use(schemaFormHelperPlugins)
    Vue.use(schemaFormPlugin)

  }
};
export default vueSchemaFormPlugin;
