//import appConfigService from './appConfigService'

const vueDfAppHttpService = {
  name: 'appHttpService',
  http: null,
  fullPath: null,
  resultPropertyName: 'result',
  appKey: null,
  idPropertyName: 'id',
  loadApplication: function (id, next) {
  //  console.log('Loading application: '+id);
    const that = this;
    try {
      const url = this.fullPath + '/' + id + '?appKey=' + this.appKey;
      this.http.get(url).then((response) => {
        const result = response.data[that.resultPropertyName];
        return that._handleResponse(result, next);
      }).catch((error) => {
        console.error(error);
      })
    } catch (e) {
      console.error(e);
    }
  },
  loadApplications(fields, next) {
 //   console.log('Loading applications fields: ', fields);
    const that = this;
    try {
      const url = this.fullPath + '?appKey=' + this.appKey+(fields?('&fields='+fields):'');
      this.http.get(url).then((response) => {
        const result = response.data[that.resultPropertyName];
        return that._handleResponse(result, next);
      }).catch((error) => {
        // console.log(error);
        throw error;
      })
    } catch (e) {
      throw e;
    }
  },
  _handleResponse: function (result, next) {
    if (result) {
      if (next)
        next(result);
      else
        return result;
    } else {
      if (next)
        next([]);
      else
        return [];
    }
  },
  loadAllRoutes: function (next) {
    console.log('Loading all routs');
    const that = this;
    try {
      const url = this.fullPath + '/route' + '?appKey=' + this.appKey;
      this.http.get(url).then((response) => {
        const result = response.data[that.resultPropertyName];
        if (result) {
          if (next)
            next(result);
          else
            return result;
        } else {
          if (next)
            next([]);
          else
            return [];
        }
      }).catch((error) => {
        console.error(error);
      })
    } catch (e) {
      console.error(e);
    }
  },
  loadRoutes: function (application) {

  },
  saveApplication: function (model) {

  },
  saveDashboardAndRoutes: function (dashboardAndRoutes, next) {
    const url = this.fullPath + '?appKey=' + this.appKey;

    const saveObject = Object.assign({}, dashboardAndRoutes);

    saveObject[this.idPropertyName] = dashboardAndRoutes.appRootPath;

    try {
      this.http.post(url, saveObject).then((response) => {
        next({data: dashboardAndRoutes});
      }).catch((error) => {
        console.error(error);
        next({data: dashboardAndRoutes});
      })
    } catch (e) {
      console.error(e);
      next({data: dashboardAndRoutes});
    }
  },
  saveRoute: function (routeObj, next) {

    const url = this.fullPath + '/route' + '?appKey=' + this.appKey;

    const saveObject = Object.assign({}, routeObj);

    saveObject[this.idPropertyName] = routeObj.applicationName + '::' + routeObj.routeKey;

    try {
      this.http.post(url, saveObject).then((response) => {
        next({data: routeObj});
      }).catch((error) => {
        console.error(error);
        next({data: routeObj});
      })
    } catch (e) {
      console.error(e);
      next({data: routeObj});
    }

  },
  install(Vue, options) {

    this.fullPath = options.fullPath;
    if (options.resultPropertyName)
      this.resultPropertyName = options.resultPropertyName;
    if (options.idPropertyName)
      this.idPropertyName = options.idPropertyName;

    this.appKey = options.appKey;

    this.http = Vue.axios;
  }

}

export default vueDfAppHttpService
